import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BookingService, NavigationService } from 'app-components';
import { AppSettingsService } from 't4-app-integration';
import { CustomerInfo, PageBase, UIService, UserSignInResult, UserValidationResult } from 't4core';
import { CustomerFormComponent } from '../../../Booking/Components/customer-form/customer-form.component';
import { VerifyAccountComponent } from '../../Components/verify-account/verify-account.component';

enum SignInStep {
  createAccount = 0,
  verifyPhone = 1
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent extends PageBase implements OnInit {
  public fromPath: string;
  public keyboardOpen = false;

  public currentStep: SignInStep = SignInStep.createAccount;
  public codeSentAgain: boolean = false;
  public validationFailed: boolean = false;
  public firstCode: boolean = true;

  public customer: CustomerInfo = new CustomerInfo();

  private previousSignInStep: SignInStep;
  private codeSent: boolean = false;

  @ViewChild("frmCustomer") public frmCustomer: CustomerFormComponent;
  @ViewChild("frmValidation") public frmValidation: VerifyAccountComponent;

  constructor(
    public appSettings: AppSettingsService,
    private navigator: NavigationService,
    el: ElementRef,
    private BookingDataService: BookingService,
    private ui: UIService
  ) {
    super(el);

    this.customer.MobilePhone = "";
    this.fromPath = this.navigator.getParam("fromPath");
  }

  public async ngOnInit() {
    let loadToken: string = this.ui.beginLoading('Intellitrailer.SignIn.CheckForLoggedInUser', 'Checking for user', null);
    let details = await this.appSettings.getUser();
    if (details.userLoggedIn) {
      var state = this.BookingDataService.getBookingState();

      //Check if user has saved customer
      state.booking.Customer = await this.Api.get<CustomerInfo>("/Rental/FindCustomerByUser");
      if (!state.booking.Customer) {
        state.booking.Customer = new CustomerInfo();

        if (details.userDetails?.MobilePhone) {
          state.booking.Customer.SSN = details.userDetails.MobilePhone;
          state.booking.Customer.MobilePhone = details.userDetails.MobilePhone;
        }

        if (details.userDetails?.Email) {
          state.booking.Customer.Email = details.userDetails.Email;
        }
      }

      this.BookingDataService.saveState(state);
      this.navigator.executeCommand("Next");
    }
    this.ui.loaderCompleted(loadToken);
  }


  public async sendVerificationCode(userSent: boolean = false) {
    let mobilePhone: string = this.customer.MobilePhone;

    let result = await this.Api.get<UserSignInResult>("Rental/BeginDigitalUserSigning", { number: mobilePhone, newNumber: null });
    if (result && result.Success) {
      this.codeSent = true;
    }
    else {
      this.handleUserSigningError(result.ErrorCode);
    }

    if (userSent && this.codeSent) {
      this.codeSentAgain = true;
      this.firstCode = false;
      setTimeout(() => this.codeSentAgain = false, 10000);
    }
  }

  public async keyboardToggle(open) {
    this.keyboardOpen = open;
  }


  // maybe move this to verify-account and call signIn upon success?
  public async verify(code: string) {
    this.validationFailed = false;

    var token = this.UI.beginLoading("Intellitrailer.SignIn.Verifying", "Verifying", null);

    let payload: any = {
      number: this.customer.MobilePhone,
      email: this.customer.Email,
      token: code,
    };

    let response: UserValidationResult = await this.Api.get<UserValidationResult>("Rental/ValidateUserSignature", payload);

    if (response.Validated) {
      this.appSettings.setUser(response.UserId);

      if (response.IsNew) {
        // === Temporarily Solution for use4free.
        //If we are in the login step, where we haven't selected a station,we set the localLocationId Temporarly so we can set the region context in the navigation step.
        //If localLocationId is set already, we should be in the booking flow.
        if (this.appSettings.contextId == 'KanLeiesUnmanned_API' && !this.appSettings.localLocationId) {
          var landCode = this.customer.MobilePhone.substring(0, 3);

          switch (landCode) {
            case '+45':
              this.appSettings.localLocationId = 10017 //BAUHAUS Ishøj
            case '+46':
              this.appSettings.localLocationId = 12594 // Use4free Stigamo
            case '+47':
              this.appSettings.localLocationId = 12625; // Byggmax Abildsø
            case '+49':
              this.appSettings.localLocationId = 12714 // Matthies Gartenfachzentrum Gmbh & Co. Kg
            case '+xx':
            default:
          }
        }
        this.navigator.executeCommand("NewCustomer")
      }
      else
       this.navigator.executeCommand("Next");

    } else {
      this.validationFailed = true;
    }

    this.UI.loaderCompleted(token);
  }

  public async confirmData(isValid: boolean) {
    if (!isValid) return;

    this.currentStep = SignInStep.verifyPhone;
    setTimeout(() => this.frmValidation.focus(), 500);
    this.sendVerificationCode();
    return;
  }

  private async handleUserSigningError(errorCode: Number) {
    switch (errorCode) {
      case 5: // Code not sent
        await this.UI.alert(
          "Intellitrailer.MyPage.ValidateAccount.SMSError.Headline",
          "The SMS could not be sent",
          "Intellitrailer.MyPage.ValidateAccount.SMSError.Headline",
          "The SMS could not be sent. Please try again or contact support if the issue persists."
        );
        return;
      case 6: // Another user with this phone number exists
        await this.UI.alert(
          "Intellitrailer.MyPage.ValidateAccount.SMSNumberExists.Headline",
          "Phone number linked to another account",
          "Intellitrailer.MyPage.ValidateAccount.SMSNumberExists.Headline",
          "Another account is already linked to this phone number."
        );
        return;
      case 3: // Code not sent
        await this.UI.alert(
          "Intellitrailer.MyPage.ValidateAccount.SMSError.Headline",
          "The SMS could not be sent",
          "Intellitrailer.MyPage.ValidateAccount.SMSError.Headline",
          "The SMS could not be sent. Please try again or contact support if the issue persists."
        );
        return;
      default:
        return;
    };
  }
}
