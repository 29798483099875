import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BookingModel, BookingService, NavigationService } from 'app-components';
import { AppSettingsService, AppTypeSettings } from 't4-app-integration';
import { LocationInfo, PageBase } from 't4core';
import { LocationMapComponent } from '../../../../lib/Components/location-map/location-map.component';

@Component({
  selector: 'app-ow-home',
  templateUrl: './OneWayStart.component.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class OneWayStartComponent extends PageBase implements OnInit {
  public bookingState: BookingModel;
  public initialStep: boolean = false;
  public userLoggedIn: boolean = false;
  public showSearch: boolean = false;
  public selectedLocation: any;
  public typeSettings: AppTypeSettings;
  public locations: LocationInfo[];

  public backgroundImageUri: string = "";

  //Google maps
  @ViewChild('map', { static: true }) gmapElement: LocationMapComponent;

 

  constructor(
    el: ElementRef,
    private aviationService: NavigationService,
    private BookingDataSerivce: BookingService,
    private appSettings: AppSettingsService)
  {
    super(el);
    this.typeSettings = this.appSettings.typeSettings;

    var rnd = Math.round(Math.random() * 15) + 1;
    this.backgroundImageUri = "url(/Styles/OneWay/Images/bg/" + rnd + ".jpg)";

    this.userLoggedIn = appSettings.userId ? true : false;
    this.bookingState = this.BookingDataSerivce.getBookingState();
  }

  async ngOnInit() {
    await this.appSettings.waitForAuthentication();
    
    //await this.BookingDataSerivce.getLocations(null, null, true);
    await this.BookingDataSerivce.getLocations();
    await this.BookingDataSerivce.startSession();
  }

  public toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  public async start():Promise<any> {

      this.aviationService.executeCommand("Start");
  }

  public mybooking() {
    let user = this.appSettings.userId;
    if (!user) {
      this.aviationService.executeCommand("SignIn");
    }
    else {
      this.aviationService.executeCommand("Bookings");
    }
  }

  public toggleNewUserInfo(bool = true) {
    localStorage.removeItem('newInfoClosed');
    this.aviationService.executeCommand("HowTo");
  }

  private setMarkerImage(marker: any, location: LocationInfo) {
    const theme = this.appSettings.settings.theme || 'Default';
    marker.setIcon(`/Styles/${theme}/Pin.png`);
  }
}
