import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ComponentBase, RentalInfo, BookingErrorCode, RentalStatus } from 't4core';
import * as moment from 'moment';
import { TrailerInfoComponent } from '../../../Rental/dialogs/trailer-info/trailer-info.component';
import { AppSettingsService } from 't4-app-integration';

interface Event {
  navigate: string,
  booking: RentalInfo,
  event?: string
}

@Component({
  selector: 'app-booking-overview',
  templateUrl: './booking-overview.component.html',
})
export class BookingOverviewComponent extends ComponentBase implements OnInit {
  @Input() public booking: RentalInfo;
  @Output() cancelled: EventEmitter<number> = new EventEmitter();
  @Output() navigate: EventEmitter<Event> = new EventEmitter();
  public showMenu: boolean = false;
  public status = RentalStatus;
  public countdown: moment.Duration;
  public late: boolean;

  constructor(public appSettings: AppSettingsService) {
    super();
  }

  ngOnInit(): void {

  }

  secondTick() {
    if (this.booking) {
      this.countdown = moment.duration(this.booking.ReturnTime.diff(moment()));
    }
  }

  public async cancel() {
    let isUserCancelConfirmed:boolean = await this.UI.confirm("Intellitrailer.MyPage.BookingOverview.ConfirmBookingCancellation.Headline", "Confirm cancellation", "Intellitrailer.MyPage.BookingOverview.ConfirmBookingCancellation.Message", "Do you want to cancel this booking?");

    if (!isUserCancelConfirmed) return;

    let token = this.UI.beginLoading("Intellitrailer.MyPaage.Overview.CancelBookingMsg", "Canceling booking", null);
    let result = await this.Api.delete<BookingErrorCode>("/Rental/Cancel",
      {
        booking: this.booking.Id
      });
    this.UI.loaderCompleted(token);
    if (result == BookingErrorCode.Valid) {
      this.UI.alert("Intellitrailer.MyPage.BookingOverview.CancelBookingSuccess.Title", "Cancelled!", "Intellitrailer.MyPage.BookingOverview.CancelBookingSuccess.Message", "The booking has been cancelled. Any paid amount will be refunded shortly.");
      this.cancelled.emit(this.booking.Id);
    }
    else {
      this.UI.alert("Turbo.Core.BookingLink.resendEmailFailed.Headline", "Failed!", "Intellitrailer.MyPage.BookingOverview.CancelBookingFailed.Message", "The booking could not be cancelled. Please try again.");
      this.cancelled.emit(null);
    }
  }


  public async change() {
    var emitInfo: Event = { navigate: "ChangeBooking", booking: this.booking };
    this.navigate.emit(emitInfo);
  }

  public async openBooking() {
    var emitInfo: Event = { navigate: "", booking: this.booking };
    if (this.booking.Status > this.status.ReservedAndBound && this.booking.Status < this.status.Returned) {
      emitInfo.navigate = "Rental.InFlight";
      this.navigate.emit(emitInfo);
    }
    else {
      emitInfo.navigate = "Rental.BookingInfo";
      this.navigate.emit(emitInfo);
    }
  }

  public async createFaultReport() {
    var emitInfo: Event = { navigate: "FaultReport", booking: this.booking, event: "FaultReport" };
    this.navigate.emit(emitInfo);
  }

  public sendEmail() {
    this.Api.get("Rental/resendEmailConfirmation", { rentalId: this.booking.Id });
  }

  public openTrailerInfo() {
    const dialogRef = this.Dialog.open(TrailerInfoComponent, {
      width: '350px',
      data: this.booking.ObjectId
    });

    dialogRef.afterClosed().subscribe(async result => {
    });
  }
}
