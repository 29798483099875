import { Component, HostListener, isDevMode, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActiveRentalService, BookingService, NavigationService } from 'app-components';
import { ApiService, AuthService, LocalStorage, RentalInfo, SettingsService, T4Environment, UIService } from 't4core';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { LayoutService } from 'app-components';
import { fader } from './route-animations';
import { MatDialog } from '@angular/material/dialog';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { TranslationService } from 't4core';
import { Router } from '@angular/router';
import { InsightsService } from 't4core';

@Component({
  selector: 'app-root[osk-root]',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = "app";
  public menuToggle = false;
  public menuComponentToggle = false;
  public firstClick = true;
  public isShowMenu: boolean = true; 
  public pageHeight: number;
  public viewHeight: number;
  public hideBackButton: boolean = false;
  public load: string = "loaded";
  public showCancelLoad: boolean = false;
  public openDialogCounter: number = 0;
  public isApp: boolean;
  public isStarting: boolean = true;
  private height: number = 0;

  public showTestMessage: boolean = true;
  constructor(
  
    public t: Title,
    private navigation: NavigationService,
    public appSettings: AppSettingsService,
    public UI: UIService,
    public dialog: MatDialog,
    public layout: LayoutService,
    public appSvc: AppIntegrationService,
    private bookingSvc: BookingService,
    private settings: SettingsService,
    private auth: AuthService,
    private renderer: Renderer2,
    private appIntegration: AppIntegrationService,
    private translate: TranslationService, private api: ApiService, private router: Router, private rentalService: ActiveRentalService, private insights: InsightsService)
  {
    this.auth.clearTokens(new Date(2023, 4, 5));
    this.appIntegration.nfcDetected.subscribe(x => {

      var parts = x.split('://');
      var app = parts[0];
      var regno = "";
      var action = "";

      if (parts.length > 1) {
        parts = parts[1].split('#');
        regno = parts[0];
        action = parts.length > 1 ? parts[1] : '';
      }

      if (this.appSettings.userId) {
        if (action == "Pickup") this.rentalService.pickupByNfc(regno);
        if (action == "Return") this.rentalService.returnByNfc(regno);
      }
    });

    this.appSettings.getClientVersion(true).then(x => {
      setInterval(() => appSettings.getClientVersion().then(x => {
        if (!x[1]) navigation.reload();
      }), 1800000);
    });
    
    this.isShowMenu = appSettings.appType === 'app';
    this.isApp = this.appSettings.appType === 'app';
  }

  public async ngOnInit() {
    

    this.pageHeight = window.screen.height;
    this.viewHeight = window.innerHeight;

    this.isStarting = false; 
    if (!this.isApp) { 
      this.appSettings.setUser(null);
    }

    this.setupHeightMessage();

      this.insights.logEvent("Web app loading complete");
    this.appSvc.notifyLoadingComplete();
  }

  public back() {
    if (this.hideBackButton) return;

    this.hideBackButton = true;
    this.navigation.back();
    this.layout.clear();

    setTimeout(() => {
      this.hideBackButton = false;
    },
      500);
  }

  public showMenu() {
    this.appSvc.requestDeviceState();
    this.menuComponentToggle = !this.menuComponentToggle;

    if (!this.menuToggle || this.firstClick) {
      this.menuToggle = !this.menuToggle;
      this.firstClick = false;
    }

    else {
      setTimeout(() => {
        this.menuToggle = !this.menuToggle;
      },
        100);

    }
  }

  public displayCancelLoad() {
    this.showCancelLoad = true;
  }

  public cancelLoad() {
    this.showCancelLoad = false;
  }

  prepareRoute(outlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  private setupHeightMessage() {
    if (this.isApp) return;
    const message = () => {
      //let contentHeight = document.querySelector('.view-content') !== null ? document.querySelector('.view-content').clientHeight : 768;
      let contentView = document.querySelector('.view-content');

      let contentHeight = 768;
      if (contentView !== null) {
        contentHeight = contentView.clientHeight;
      }
      if (contentHeight === this.height) return;

      //let headerHeight = document.querySelector('.view-content') !== null ? document.querySelector('.head').clientHeight : 0;
      let headerHeight = 0;
      let headAction = document.querySelector('.head');
      if (headAction !== null) {
        headerHeight = headAction.clientHeight;
      }


      //let footerHeight = document.querySelector('.view-actions') != null ? document.querySelector('.view-actions').clientHeight : 0;
      let footerHeight = 0;
      let contentAction = document.querySelector('.view-actions');
      if (contentAction !== null) {
          footerHeight = contentAction.clientHeight;
      }

      this.height = contentHeight;
      parent.postMessage(headerHeight + contentHeight + footerHeight, '*');
    };
    setInterval(message, 100);
  }

  public hideTestMessage() {
    this.showTestMessage = false;
    setTimeout(() => this.showTestMessage = true, 5000);
  }
}
