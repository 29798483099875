import { Component, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { PageBase, TypeCategoryInfo, ObjectTypeInfo } from 't4core';
import { NavigationService } from 'app-components';
import { BookingService } from 'app-components';
import { TypeGroup } from 'app-components';

@Component({
    selector: 'app-category-selector',
    templateUrl: './category-selector.html'
})

export class CategorySelector extends PageBase {
    public categories: TypeCategoryInfo[] = [];
    public categorySelections: boolean[] = [];
    @Input() public autoSelect: boolean = false;
    @Input() public disableUnselectable: boolean = false;

    @Input() set bookingOptions(options: TypeGroup[]) {
        var categoryAvailability: boolean[] = [];

        for (var i = 0; i < this.categories.length; i++) {
            var x = options.find(y => y.Category == this.categories[i]);

            if (x.Options.length > 0) categoryAvailability[i] = true;
            else categoryAvailability[i] = false;
        }
    }

    public categoryAvailability: boolean[];

    @Input() public selectedCategory: TypeCategoryInfo;
    @Output() public selectedCategoryChange = new EventEmitter<TypeCategoryInfo>();

    @Input() public selectedCategories: TypeCategoryInfo[] = [];
    @Output() public selectedCategoriesChange = new EventEmitter<TypeCategoryInfo[]>();

  constructor(private navigator: NavigationService, el: ElementRef, private BookingDataService: BookingService) {
      super(el);
      this.loadCategories();
    }

    public async loadCategories() {
    this.categories = await this.BookingDataService.getCategories();
        for (let cat of this.categories) {
            if (this.selectedCategories.findIndex(x => x.Id == cat.Id) > -1) {
                this.categorySelections.push(true);
            }
            else {
                this.categorySelections.push(false);
            }
        }
        if (this.autoSelect) {
            this.selectCategory(this.categories[0]);
        }
    }

    public selectCategory(categoryType: TypeCategoryInfo) {


        var index = this.selectedCategories.findIndex(x => x.Id == categoryType.Id);

        if (index == -1) {
            this.selectedCategories = [categoryType];
            //this.selectedCategories.push(categoryType);

            for (let i = 0; i < this.categorySelections.length; i++) {
                this.categorySelections[i] = false;
            }

            //Save in selections array
            var i = this.categories.findIndex(x => x.Id == categoryType.Id);
            this.categorySelections[i] = true;
        }
        else {
            this.selectedCategories = [];
            //this.selectedCategories.splice(index, 1);

            //Remove from selections array
            //var i = this.categories.findIndex(x => x.Id == categoryType.Id);
            //this.categorySelections[i] = false;

            for (let i = 0; i < this.categorySelections.length; i++) {
                this.categorySelections[i] = false;
            }

        }
        this.selectedCategoriesChange.emit(this.selectedCategories);

    }

}
