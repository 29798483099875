import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { AppSettingsService } from 't4-app-integration';

export interface LayoutSettings {
  title: string,
  header: HeaderSettings;
}

export interface HeaderSettings {
  visible?: boolean;
  overlay?: boolean;
  showBack?: boolean;
  showMenu?: boolean;

  isPrimaryColor?: boolean;
  isSplitColor?: boolean;
  isGreen?: boolean;
  isRed?: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public title: string;
  public header: HeaderSettings = this.getDefaultHeaderSettings();


  private getDefaultHeaderSettings(): HeaderSettings {
    return {
      visible: true,
      showBack: true,
      showMenu: this.appSettings?.typeSettings?.isShowMenu ?? true,
      overlay: false,

      isPrimaryColor: false,
      isSplitColor: false,
      isGreen: false,
      isRed: false
    };
  }

  constructor(private appSettings: AppSettingsService, @Inject(DOCUMENT) private document: Document) { }

  public update(settings: LayoutSettings) {
    if (!settings) return;

    this.title = settings.title;
    if (settings.header) {
      this.header = this.mergeSettings<HeaderSettings>(this.getDefaultHeaderSettings(), settings.header);
    }

    this.header.showMenu = this.appSettings?.typeSettings?.isShowMenu ?? this.header.showMenu;
  }

  public clear() {
    this.title = "";
    this.header = this.getDefaultHeaderSettings();
  }

  private mergeSettings<T>(obj1: T, obj2: T): T {
    var ret = { ...obj1 };

    for (var key in obj2) {
      if (obj2[key] !== undefined && obj2[key] !== null)
        ret[key] = obj2[key];
    }
    return ret
  }

  public loadJsScript(renderer: Renderer2, src: string, id:string): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.id = id;
    renderer.appendChild(this.document.body, script);
    return script;
  }
}
