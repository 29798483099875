import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { AppSettingsService } from 't4-app-integration';


@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css']
})
export class ChatbotComponent implements OnInit {
  zendeskKey = 'fa045ccb-5bab-4193-bff5-7ec511bf2836';
  lang = 'sv';

  constructor(private renderer: Renderer2, public appSettings: AppSettingsService) { }

  ngOnInit(): void {
    if (this.appSettings.appType == 'app' && this.appSettings.contextId.toLowerCase() == 'kanleiesunmanned_api') {
      setTimeout(() => this.loadZendeskScript(), 5000);
    }
  }

  loadZendeskScript(): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.id = 'ze-snippet';
    script.charset = 'utf-8';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${this.zendeskKey}`;
    script.onload = () => {
        (window as any).zE('webWidget', 'setLocale', this.appSettings.getLanguage());
    };

    script.onerror = (error) => { console.error('Error loading the zendesk script:', error); };

    this.renderer.appendChild(document.head, script);
  }
}
