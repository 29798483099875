<div *ngIf="toggle" @flyInOut class="menu-container">
  <div class="container menu-inner-container">
    <mat-icon class="close" (click)="close()">close</mat-icon>
    <div class="row app-icon" style="padding:25px">
      <img *ngIf="appSettings.settings.theme" src="/Styles/{{appSettings.settings.theme}}/Logo.png" style="max-width: 200px; max-height: 100px; margin: auto;" />
      <img *ngIf="!appSettings.settings.theme" src="" style="width: 200px; height: 100px; margin: auto;" />

      <div style="width: 100%;">
        <div class="logo" style="text-align: center;">
          <div class="row login-button-wrapper" *ngIf="userLoggedIn" style="text-align: center;">
            <span class="login-button" (click)="signOut()">
              <span class="menu-item-text">{{ 'Intellitrailer.Menu.SignOut' | translate : 'Sign out' }}</span>
              <span class="material-icons">
                login
              </span>
            </span>
          </div>
          <!--<button *ngIf="userLoggedIn" style="margin-top: 25px;" mat-button (click)="deleteAccount()">Delete my account</button>-->
          <div class="row login-button-wrapper" *ngIf="!userLoggedIn">
            <span class="login-button" (click)="navigate('MyPage.SignIn')">
              <span class="menu-item-text">{{ 'Intellitrailer.Menu.SignIn' | translate : 'Sign in' }}</span>
              <span class="material-icons">
                account_box
              </span>
            </span>
          </div>
        </div>
      </div>

    </div>
    <!--<div class="row">
      <div *ngIf="userLoggedIn" class="user-icon">
        <span class="material-icons">
          account_circle
        </span>
      </div>
      <div class="user-info">
        <span *ngIf="userLoggedIn && customer && customer.Firstname && customer.Lastname">{{ customer.Firstname }} {{ customer.Lastname }}</span>
        <span *ngIf="userLoggedIn && userDetails">{{ userDetails.Email }}</span>
      </div>
    </div>-->
    <div style="overflow-y: auto; overflow-x: hidden; height: calc(100% - 340px); display: flex; flex-direction: column;">

      <ng-container>
        <!--<div class="row">
          <div class="menu-item" (click)="navigate('Start')">
            <span class="material-icons icon">home</span>
            <span class="menu-item-text">{{ 'Intellitrailer.MyPage.Overview.TitleStartpage' | translate : 'Start page' }}</span>
            <span class="material-icons">chevron_right</span>
          </div>
        </div>-->
        <div class="row" (click)="navigate('Start')">
          <div class="menu-item new-booking">
            <span class="material-icons icon">add</span>
            <span class="menu-item-text">{{ 'Intellitrailer.Booking.BeginBooking.TitleNewBooking' | translate : 'New booking' }}</span>
            <span class="material-icons">chevron_right</span>
          </div>
        </div>
        <div class="row">
          <div class="menu-item" (click)="navigate('MyPage.Overview')">
            <span class="material-icons icon">account_box</span>
            <span class="menu-item-text">{{ 'Intellitrailer.MyPage.Overview.TitleMyBookings' | translate : 'My bookings' }}</span>
            <span class="material-icons">chevron_right</span>
          </div>
        </div>
        <div class="row" *ngIf="!userLoggedIn">
          <div class="menu-item" (click)="navigate('MyPage.Settings')">
            <span class="material-icons icon">settings</span>
            <span class="menu-item-text">{{ 'Intellitrailer.Menu.MenuItem.Settings' | translate : 'Settings' }}</span>
            <span class="material-icons">chevron_right</span>
          </div>
        </div>
        <div class="row" *ngIf="userLoggedIn">
          <div class="menu-item" (click)="navigate('MyPage.Settings.Authenticated')">
            <span class="material-icons icon">settings</span>
            <span class="menu-item-text">{{ 'Intellitrailer.Menu.MenuItem.Settings' | translate : 'Settings' }}</span>
            <span class="material-icons">chevron_right</span>
          </div>
        </div>

        <div class="row">
          <ng-container *ngIf="!loading">
            <div class="menu-item" *ngFor="let option of this.menuOptions" (click)="navigate(option.route)">
              <span class="material-icons icon"></span>
              <span class="menu-item-text">{{ 'Intellitrailer.Menu.CustomMenuItem.' + option.label | translate }}</span>
              <span class="material-icons">chevron_right</span>
            </div>
          </ng-container>
          <ng-container *ngIf="loading">
            <div style="margin:auto; padding-top:15px; width: 100px; height: 100px; top:55vh; left: 35%; display: inline-block">
              <mat-progress-spinner diameter="50" mode="indeterminate" style="margin:auto"></mat-progress-spinner>
              <div style="text-align: center; width: 100px; margin-top: 25px">{{ 'Intellitrailer.Menu.LoadingMenu' | translate : 'Loading menu' }}</div>
            </div>
          </ng-container>
        </div>



      </ng-container>
      
      <div style="margin-top: auto; margin-bottom: 25px; text-align: center;">

        <div style="position: absolute; bottom: 10px; width: calc(100% - 30px); text-align: center; background: white; ">
          <div *ngIf="appSettings.meetsMinimumAppVersion(4.0)" (click)="appService.openPermissions()" style="margin-bottom: -15px;">
            <mat-icon style="margin: 0 15px;" *ngIf="appService.Device.Bluetooth == 1 || appService.Device.Bluetooth == 3" [ngClass]="{ red: appService.Device.Bluetooth == 0 || appService.Device.Bluetooth == 2, yellow: appService.Device.Bluetooth == 1, green: appService.Device.Bluetooth == 3 }">bluetooth</mat-icon>
            <mat-icon style="margin: 0 15px;" *ngIf="appService.Device.Bluetooth == 0 || appService.Device.Bluetooth == 2" [ngClass]="{ red: appService.Device.Bluetooth == 0 || appService.Device.Bluetooth == 2, yellow: appService.Device.Bluetooth == 1, green: appService.Device.Bluetooth == 3 }">bluetooth_disabled</mat-icon>
            <mat-icon style="margin: 0 15px;" *ngIf="appService.Device.Location == 1 || appService.Device.Location == 3" [ngClass]="{ red: appService.Device.Location == 0 || appService.Device.Location == 2, yellow: appService.Device.Location == 1, green: appService.Device.Location == 3 }">location_on</mat-icon>
            <mat-icon style="margin: 0 15px;" *ngIf="appService.Device.Location == 0 || appService.Device.Location == 2" [ngClass]="{ red: appService.Device.Location == 0 || appService.Device.Location == 2, yellow: appService.Device.Location == 1, green: appService.Device.Location == 3 }">location_off</mat-icon>
            <mat-icon style="margin: 0 15px;" [ngClass]="{ red: appService.Device.Camera == 0 || appService.Device.Camera == 2, yellow: appService.Device.Camera == 1, green: appService.Device.Camera == 3 }">photo_camera</mat-icon>
          </div>
          <ng-container *ngIf="showCustomerInfo && CustomerInfoList.length > 0">
            <div style="display: flex; align-items: baseline; justify-content: center;">
              <p style=" font-weight: bold; margin:0 0 10px 30px;"> {{ 'Intelitrailer.Menu.CustomerService' | translate : 'Customer service'}}</p>
              <ng-container *ngIf="!loading">
                <button mat-icon-button [matMenuTriggerFor]="menu" style="height:40px; width:40px;">
                  <img width="32" height="32" style="margin-right: 10px;" [src]="country.flagPath">
                </button>
                <div>
                  <mat-icon style="vertical-align:bottom;" [matMenuTriggerFor]="menu">arrow_drop_down</mat-icon>
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="!loading">
              <a href="tel:{{selectedCustomerInfo.phone}}" *ngIf="selectedCustomerInfo.phone" style="margin:0 0 5px 0; font-size:10pt;"><mat-icon style="vertical-align: middle;">call</mat-icon> {{selectedCustomerInfo.phone}}</a>
              <p *ngIf="selectedCustomerInfo.email" style="margin:0 0 5px 0; font-size:10pt;"><mat-icon style="vertical-align: middle;">mail</mat-icon> {{selectedCustomerInfo.email}}</p>
            </ng-container>
            <ng-container *ngIf="loading">
              <div style="margin:auto; width: 45px; height: 45px; top:55vh; left: 35%; display: inline-block">
                <mat-progress-spinner diameter="35" mode="indeterminate" style="margin:auto"></mat-progress-spinner>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="showCustomerInfo && CustomerInfoList.length <= 0">
            <div style="display: flex; align-items: center; justify-content: center; flex-direction:column">
              <p style=" font-weight: bold;"> {{ 'Intelitrailer.Menu.CustomerService' | translate : 'Customer service'}}</p>
              <p style="margin:0 0 5px 0; font-size:10pt;"> {{ 'Intelitrailer.Menu.CustomerServiceFallBack' | translate : 'For assistance, contact the local store directly.'}}</p>
            </div>
          </ng-container>

          <!-- Menu for Languages - Customer Support Info -->
          <mat-menu #menu="matMenu" xPosition="after">
            <button mat-menu-item *ngFor="let c of countries" (click)="selectCountry(c)">
              <img width="32" height="32" [src]="c.flagPath">   {{ c.fullName }}
            </button>
          </mat-menu>

          <div class="row mt-2 text-center" style=" font-size: 14px" *ngIf="version">
            <div class="col-12" style="padding:5px; ">{{ 'Intellitrailer.Menu.Version' | translate : 'Version' }} {{version}}<ng-container *ngIf="shellVersion"> ({{shellVersion}})</ng-container></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
