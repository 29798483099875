import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ComponentBase, CustomerInfo, SettingsService } from 't4core';
import { Country, getCountries, getCountryByCode, getCountryByLanguageCode, getCountryDataByName } from '../../Models/Country';
import { NavigationService, NavigationResolver } from 'app-components';
import { AppIntegrationService, AppSettingsService, Coordinates } from 't4-app-integration';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
    animations: [
        trigger('flyInOut', [
            state('in', style({ transform: 'translateX(-100%)' })),
            transition('void => *', [
                style({ transform: 'translateX(100%)' }),
                animate(100)
            ]),
            transition('* => void', [
                animate(100, style({ transform: 'translateX(100%)' }))
            ])
        ])
  ]
})
export class MenuComponent extends ComponentBase implements OnInit {
    @Input() public toggle: boolean;
    @Output() toggleMenu: EventEmitter<boolean> = new EventEmitter();
    public userLoggedIn: boolean = false;
    public userId: string = "";
    public customer: CustomerInfo;
    public userDetails: any;
    public version: string;
    public shellVersion: string;
    public menuOptions: { language: string; label: string; route: string }[] = [];

    public showCustomerInfo: boolean = false;
    public CustomerInfoList: CustomerInfoSettings[] = [];
    public selectedLanguage: string;
    public selectedCustomerInfo: CustomerInfoSettings;

    public userLocation: Coordinates = { Latitude: 0, Longitude: 0 };

    public country: Country;
    public countries: Country[] = getCountries();
    public language: string = this.appSettings.getLanguage();

    constructor(
      private navigationService: NavigationService,
      public appService: AppIntegrationService,
      public resolver: NavigationResolver,
      public appSettings: AppSettingsService,
      settingsService: SettingsService)
    {
      super();


      //Get the country by checking the closes station country property.
      //If we dont have an anticipiated country we check the default Brower language, and if that fails we fall back to swedish.
      var anticipatedUserCountry = this.appSettings.getAnticipatedUserCountry();
      if (anticipatedUserCountry != null) {
        this.country = getCountryDataByName(anticipatedUserCountry);
      }
      else {
        //GetBrowserLanguage have a fallback to en if none is found.
        var browserLanguage = AppSettingsService.GetBrowserLanguage();

        if (browserLanguage == "en") {
          this.country = getCountryByLanguageCode("sv");
        }
        else {
          this.country = getCountryByLanguageCode(browserLanguage);
        }
      }
    }

    public async ngOnInit() {
      this.loading = true;

      let details = await this.appSettings.getUser();
      this.customer = details.customer;
      this.userDetails = details.userDetails;
      this.userLoggedIn = details.userLoggedIn;
      this.menuOptions = this.appSettings.settings.menuOptions;
      this.version = this.appSettings.clientVersion;
      this.shellVersion = this.appSettings.appVersion == 'null' ? '' : this.appSettings.appVersion;

      //If we want to show the settings, then we create the object.
      this.showCustomerInfo = this.appSettings.settings.ShowCustomerSupportInfo;
      if (this.showCustomerInfo) {
        this.createCustomerInfoList();

        if (this.CustomerInfoList) {
          //if we have selected a support language before, we use that.
          if (this.appSettings.customerSupportLanguage != null)
            this.selectedCustomerInfo = this.CustomerInfoList.filter(x => x.language == this.appSettings.customerSupportLanguage)[0];
          //Otherwise we check if we have anticipated country we use that. And have a fallback to first object in the CustomerInfoList that should be SE.
          if (!this.selectedCustomerInfo)
            this.selectedCustomerInfo = this.CustomerInfoList.filter(x => x.language == this.country.language)[0] ?? this.CustomerInfoList[0];

          //Updates the flag to corresponde with the correct settting.
          if (this.selectedCustomerInfo)
            this.country = getCountryByLanguageCode(this.selectedCustomerInfo.language);

        }

      } 

      if (this.menuOptions)
        this.filterMenuOptions();

      this.loading = false;
  }



  public filterMenuOptions() {
    var filtertMenuOptions = this.menuOptions.filter(x => x.language == this.language);
    //Fallback option - If we have no options, we add the swedish once as default.
    if (filtertMenuOptions.length < 1) {
      filtertMenuOptions = this.menuOptions.filter(x => x.language == "sv");
    }
    this.menuOptions = filtertMenuOptions;
  }


 public selectCountry(selectedCountry: Country) {
    this.country = selectedCountry;

    const countryToLanguageMap = {
      "se": "sv",
      "dk": "da",
      "de": "de",
      "no": "no",
      "en": "en",
      "is": "is",
      "fi": "fi"
    };

    // Find the language code corresponding to the selected country
   const languageCode = countryToLanguageMap[selectedCountry.shortName];

   //Saves the language selected.
   this.appSettings.customerSupportLanguage = languageCode;

    // Find the customer information based on the language code
   this.selectedCustomerInfo = this.CustomerInfoList.find(customerInfo => customerInfo.language === languageCode);
  }


  public  createCustomerInfoList() {
    this.CustomerInfoList = this.appSettings.settings.CustomerSupportInfo;
   
    //Gets all the Countries we have added in settings.
    const languages = this.CustomerInfoList.map(item => item.language);

    const languageToCountryMap = {
      "sv": "se",
      "da": "dk",
      "de": "de",
      "no": "no",
      "en": "en",
      "is": "is",
      "fi": "fi"
    };

    //Extract country codes corresponding to languages
    const countryCodes = languages.map(lang => languageToCountryMap[lang]);

    //Filters out the countries we dont have info for
    this.countries = this.countries.filter(country => countryCodes.includes(country.shortName));
  }


  public close() {
    this.toggleMenu.emit(true);
  }


  public async navigate(command: string) {
    if (command.startsWith("http")) {
      this.appService.openExternal(command);
    }
    else {
      this.toggleMenu.emit(true);
      if (await this.navigationService.isAuthenticationRequired(command) && !this.appSettings.userId) {
        this.navigationService.navigateToView("MyPage.SignIn");
      } else {
        this.navigationService.navigateToView(command);
      }
    }
  }


  public async signOut() {
    this.navigate("Start");
    this.appSettings.setUser(null);
    await this.UI.alert(
      "Intellitrailer.Menu.LoggedOut.Headline",
      "You have been signed out",
      "Intellitrailer.Menu.SignedOut.Message",
      "To sign in again, open the menu and tap the sign in button.");
  }
}


export interface CustomerInfoSettings {
  language: string;
  email: string;
  phone: string;
}
