import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { PageBase, ExtendRentalOption, ProlongRentalResult, OpenHours, RentalInfo } from 't4core';
import * as moment from 'moment';
import { NavigationService } from 'app-components';
import { ActiveRentalService } from 'app-components';
import { LayoutService } from 'app-components';
import { Moment } from 'moment';

@Component({
  selector: 'app-extend-rental',
  templateUrl: './extend-rental.component.html',
  styleUrls: ['./extend-rental.component.css']
})
export class ExtendRentalComponent extends PageBase implements OnInit {
  public extendOption: ExtendRentalOption;
  public extendOptions: ExtendRentalOption[] = [];
  public loader: boolean = false;
  private booking: RentalInfo;
  public selected;

  constructor(
    elementRef: ElementRef,
    private navigationService: NavigationService,
    private rentalService: ActiveRentalService,
    private layoutService: LayoutService
  )
  {
    super(elementRef);
  }

    onNoClick(): void {
      this.navigationService.executeCommand('Cancel');
    }

    async ngOnInit(): Promise<void> {
        this.booking = await this.rentalService.getBooking();
        this.loader = true;
        await this.getExtendOptions();
        this.loader = false;
    }

    public async getExtendOptions() {
        this.extendOptions = await this.Api.get<ExtendRentalOption[]>("Rental/SLGetExtendOptions", { bookingCode: this.booking.Id });
        if (this.extendOptions.length > 0) {
            var validatedOptions: ExtendRentalOption[] = [];

            //Max length
            var maxLength: number;

            //Get open hours
            let openHours:OpenHours[] = await this.Api.get<OpenHours[]>("/Location/GetOpenHoursForPeriod", {
                locationId: this.booking.PickupLocationId,
                startDate: this.booking.PickupTime.clone().toISOString(),
                endDate: this.booking.ReturnTime.clone().toISOString()
            });

            let returnCloses: Moment;
            let returnT: OpenHours = openHours.find(x => x.Date.clone().startOf("day").isSame(this.booking.ReturnTime.clone().startOf('day')));

            if (returnT) {
                returnCloses = moment(returnT.Closes.clone().utc().format("YYYY-MM-DD HH:mm:ss"));
            }

            //Bauhaus varebil restrictions
            let catId:number = await this.Api.get<number>("RentalObject/GetTypeCategoryId", { objectTypeId: this.booking.PrimaryObjectTypeId });
            if (catId == 19) {
                maxLength = 180;
              returnCloses = returnCloses.clone().startOf('day').add(19, 'hours');
            }
            for (let option of this.extendOptions) {
              let valid:boolean = await this.Api.get<boolean>("Rental/SLCanExtend", { rentalId: this.booking.Id, hours: option.Hours });
                if (valid) {
                  if ((!maxLength || this.booking.PickupTime.clone().add(maxLength, 'minutes') >= this.booking.ReturnTime.clone().add(option.Hours, 'hours'))
                    && this.booking.ReturnTime.clone().add(option.Hours, 'hours') <= returnCloses) {
                        validatedOptions.push(option);
                    }
                }
            }
            this.extendOptions = validatedOptions;
        }
        if (this.extendOptions.length == 0) {
          this.UI.alert(null,
            null,
            "Intellitrailer.Rental.Component.ExtendRentalDialog.ExtendNotPossible.Message",
            "This booking can not be extended.");
          this.navigationService.executeCommand('Cancel');
        }
        else {
            this.selected = this.extendOptions[0];
        }
    }

    public async extend()
    {
        this.loader = true;
        let result = await this.Api.get<ProlongRentalResult>("Rental/SLExtend", { rentalId: this.booking.Id, hours: this.selected.Hours });
        this.loader = false;
        if (result == ProlongRentalResult.Success) {
          this.UI.alert("Intellitrailer.Rental.Component.ExtendRentalDialog.ExtendSucessful.Headline",
            "Confirmation!",
            "Intellitrailer.Rental.Component.ExtendRentalDialog.ExtendConfirmed.Message",
            "The booking has been extended.");
        }
        else {
          this.UI.alert("Intellitrailer.Rental.Component.ExtendRentalDialog.ExtendFailed.Headline",
            "Failed!",
            "Intellitrailer.Rental.Component.ExtendRentalDialog.ExtendFailed.Message",
            "The booking could not be extended. The extension collides with another booking.");
        }
      this.navigationService.executeCommand('Confirm');
  }

  public cancel() {
    this.navigationService.executeCommand('Confirm');
  }
}
