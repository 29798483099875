import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ApiService, BookingErrorCode, BookingValidation, CustomerInfo, InsightsService, LocationInfo, ObjectTypeInfo, PriceInfoModel, RentalInfo, TypeCategoryInfo, UIService } from 't4core';
import { BookingModel } from '../../Models/BookingModel';
import { AppIntegrationService, Coordinates, AppSettingsService } from 't4-app-integration';
import { TypeGroup } from '../../Models/TypeGroup';
import { NavigationService } from '../NavigationService/navigation-service.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  // Booking model
  public Query: BookingQuery = new BookingQuery();

  // View model
  private locations: LocationInfo[] = [];
  private types: ObjectTypeInfo[] = [];
  private categories: TypeCategoryInfo[] = [];
  private priceInfo: any = {};

  private getLocationsIsRunning: boolean = false;
  // Get all available locations
  public async getLocations(): Promise<LocationInfo[]> {
    // If already cached, return them from memory
    if (this.locations.length > 0) return this.locations;

    // Wait for any previous run tom complete
    while (this.getLocationsIsRunning) await this.delay(100);
    this.getLocationsIsRunning = true;

    // If already cached, return them from memory
    if (this.locations.length > 0) return this.locations;

    // Retrieve from api
    this.locations = await this.Api.get<LocationInfo[]>("Location/GetLocations", { subcontext: this.appSettings.getParam("subcontext"), withCategories: true});

    // Return the data
    this.getLocationsIsRunning = false;
    return this.locations;
  }

  constructor(
    private appService: AppIntegrationService,
    private Api: ApiService,
    private UI: UIService,
    public appSettings: AppSettingsService,
    private insights: InsightsService) {
      // Has the module been loaded with the inStore-parameter?
    this.inStore = this.appSettings.getParam("inStore") == "true";
  }

  public startNew() {
    this.Query = new BookingQuery();
  }


  /******* Der alte shit *******/
  public preLoadedCategories: TypeCategoryInfo[];

  private bookingData: BookingModel = {
    booking: new RentalInfo(),
    timeSteps: "quarter",
    session: new Date().getTime(),
    existingBooking: null,
    objectCategories: this.categories,
  };

  public maxObjects: number = 5;
  public loading: boolean = false;
  public command: string;


  // Resets all values and starts a new session
  public async startSession(): Promise<boolean> {

    this.bookingData = {
      booking: new RentalInfo(),
      timeSteps: "quarter",
      session: new Date().getTime(),
      existingBooking: null,
      objectCategories: [],
    };

    this.maxObjects = 5;
    this.loading = false;
    this.command = null;

    // Load initial data
    if (!this.locations || this.locations.length <= 0) {
      var success = await this.getLocations();
      if (!success) { return false; }
    }

    if (!this.appSettings.getAnticipatedUserCountry()) {
      // Get coordinates to determine country
      this.appService.getCoordinates(false).then(coords => this.appSettings.registerLastKnownPosition(coords));
    }
    

    if (this.appSettings.getParam("pickupLocationId")) {
      var loc = this.locations.find(x => (x.Id + "") == this.appSettings.getParam("pickupLocationId"));
      if (loc) {
        this.setPickupLocation(loc);
      }
    }

    //If we are in the web view, to be sure we clear the user.
    if (this.appSettings.appType == "web") {
      this.appSettings.setUser(null);
    }

    return true;
  }


  // Returns the closest location with the Cords procivded.
  public findClosestLocation(currentLat: number, currentLon: number): LocationInfo | null {
    const filteredLocations = this.locations.filter(x => x.OpenForPickup);
    if (filteredLocations.length === 0) return null;

    return filteredLocations.reduce((closest, current) => {
      const closestDistance = this.calculateDistance(currentLat, currentLon, closest.TrailerPointLatitude, closest.TrailerPointLongitude);
      const currentDistance = this.calculateDistance(currentLat, currentLon, current.TrailerPointLatitude, current.TrailerPointLongitude);
      return currentDistance < closestDistance ? current : closest;
    });
  }

  //Caluclates the distance - Haversine forumla
  private calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const earthRadius = 6371; // Radius of the Earth in kilometers
    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.degreesToRadians(lat1)) * Math.cos(this.degreesToRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c; // Distance in kilometers
    return distance;
  }

  // Helper -- function to convert degrees to radians
  private degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  

  public async restartSession(rentalId: number): Promise<boolean> {
    var booking = await this.Api.get<RentalInfo>("/Rental/GetRentalInfo", { bookingId: rentalId });
    this.appSettings.localLocationId = booking.PickupLocation.Id;

    this.bookingData = {
      booking: booking,
      timeSteps: "quarter",
      session: new Date().getTime(),
      existingBooking: null,
      objectCategories: [],
    };

    this.maxObjects = 5;
    this.loading = false;
    this.command = null;

    // Load initial data
    if (!this.locations || this.locations.length <= 0) {
      var success = await this.getLocations();
      if (!success) { return false; }
    }

    return true;
  }

  /// Booking building methods
  async setPickupLocation(pickupLocation: LocationInfo) {
    this.bookingData.booking.PickupLocation = pickupLocation;
    this.priceInfo = null;

    if (pickupLocation) {
      this.bookingData.booking.PickupLocationId = pickupLocation.Id;
      var distance = await this.appService.getDistanceFromUser(pickupLocation.TrailerPointLatitude, pickupLocation.TrailerPointLongitude) * 1000;

      this.bookingData.atLocation = (distance < pickupLocation.TrailerPointRadius) && !this.bookingData.booking?.Id;
    }
  }

  setCategory(objectCategory: TypeCategoryInfo) {
    this.bookingData.objectCategory = objectCategory;
  }

  setRentalPeriod(pickupTime: moment.Moment, returnTime: moment.Moment) {
    this.bookingData.pickupTime = pickupTime;
    this.bookingData.returnTime = returnTime;
  }
  /// Booking building methods

  /// Helper methods

  /// Load price list base information from the server
  public async getPriceInfo(): Promise<PriceInfoModel> {
    if (!this.priceInfo) {
      this.priceInfo = await this.Api.get<any>("Price/GetPriceInfo", { pickupTime: new Date().toISOString(), objectType: null, webBooking: true });
    }

    return this.priceInfo;
  }

  /// Helper methods

  clearObjectType() {
    this.bookingData.booking.PrimaryType = null;
    this.bookingData.booking.PrimaryObjectTypeId = null;
  }

  getBookingState() {
    return this.bookingData;
  }

  // Get, and cache, types
  public async getType(id: number): Promise<ObjectTypeInfo> {
    var t = this.types.find(x => x.Id == id);
    if (t) return t;

    t = await this.Api.get<ObjectTypeInfo>("/RentalObject/GetTypeInfo", { objectType: id });

    this.types.push(t);
    return t;
  }

  public async getLocation(id: number): Promise<LocationInfo> {
    var loc = this.locations.find(x => x.Id == id);
    if (loc) return loc;

    await this.getLocations();

    var loc = this.locations.find(x => x.Id == id);
    return loc;
  }

  private delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


  public async isCustomerAtLocation(): Promise<boolean> {
    // Never when changing booking
    if (this.bookingData.booking?.Id) {
      this.bookingData.atLocation = false;
      return true;
    }

    // Are there locations?
    if (this.locations && this.locations.length > 0) {

      // Calculate distance and check if user is within trailer point
      var distance = await this.appService.getDistanceFromUser(this.locations[0].TrailerPointLatitude, this.locations[0].TrailerPointLongitude) * 1000;
      this.bookingData.atLocation = distance < this.locations[0].TrailerPointRadius + 1000 && !this.bookingData.booking?.Id;

      // Is the demo setting set ? The override
      if (this.appSettings.userId && localStorage.getItem('atLocation'))
        this.bookingData.atLocation = localStorage.getItem('atLocation') == "true" ? true : this.bookingData.atLocation;

      // Select the closest location
      if (this.bookingData.atLocation) {
        this.bookingData.booking.PickupLocation = this.locations[0];
      }

      return this.bookingData.atLocation;
    }

    return false;
  }

  public saveBookingState(booking: RentalInfo) {
    for (let data in booking) {
      if (booking[data] && this.bookingData.booking[data] != booking[data]) {
        this.bookingData.booking[data] = booking[data];
      }
    }
  }

  public saveState(newState: BookingModel) {
    this.saveBookingState(newState.booking);

    for (let data in newState) {
      //Check if properties of booking has changed
      if (data == "booking") continue;
      //Check if other properties of the bookingState has changed
      else {
        if (this.bookingData[data] != newState[data]) {
          this.bookingData[data] = newState[data];
        }
      }
    }

    if (this.bookingData.booking.PickupLocation) {
      this.appSettings.localLocationId = this.bookingData.booking.PickupLocation.Id;
    }

    this.bookingData.objectCategories = newState.objectCategories;
    this.bookingData.freshLoad = false;
  }

  public inStore: boolean = false;

  public async isWebBooking(): Promise<boolean> {

    if (this.inStore) return false;

    return true;
    var thirtyMinsFromNow = moment().add(30, 'minutes');
    var webBooking = !this.bookingData.atLocation;

    if (!webBooking && this.bookingData.booking.PickupTime >= thirtyMinsFromNow) {
      webBooking = true;
    }

    return webBooking;

  }

  public async saveCustomer(isOneWay: boolean = false): Promise<CustomerInfo> {
    this.Query.Customer = await this.Api.post<CustomerInfo>("/Rental/SaveCustomer", this.Query.Customer, { isOneWay: isOneWay });
    return this.Query.Customer;
  }

  public async saveBooking(webBooking: boolean = true, useSavedCard: boolean = true): Promise<any> {
    //await this.saveCustomer(this.bookingData.booking.IsOneWay);

    //Customer confirm price changes if updating existing booking
    if (this.bookingData.existingBooking) {
      var existingBooking = await this.Api.get<RentalInfo>("/Rental/GetRentalInfo", { bookingId: this.bookingData.existingBooking });
      if (this.bookingData.booking.Price.DiscountedTotalPrice > existingBooking.Price.DiscountedTotalPrice) {
        //Add code to confirm changes here if necessary in the future
        var confirm = true;

        if (!confirm) {
          return { CustomerDenied: true };
        }
      }

    }
    //If new booking
    if (!this.bookingData.existingBooking) {

      var webBooking = await this.isWebBooking();

      //Get customer current location as Coordinates.
      var currentCoordinates = await this.appService.getCoordinates();
    



      //var token = this.UI.beginLoading("Intellitrailer.Booking.Payment.msgLoadingOptionsCreate", "Creating booking", null);
      var result = await this.Api.post<BookingValidation>("Rental/CreateBooking", this.Query.Customer,
        {
          pickupTime: this.bookingData.booking.PickupTime.toISOString(),
          returnTime: this.bookingData.booking.ReturnTime.toISOString(),
          pickupLocation: this.bookingData.booking.PickupLocation.Id,
          returnLocation: this.bookingData.booking.ReturnLocation ? this.bookingData.booking.ReturnLocation.Id : this.bookingData.booking.PickupLocation.Id,
          typeId: this.bookingData.booking.PrimaryObjectTypeId,
          discountId: this.bookingData.booking.DiscountId,
          notes: this.bookingData.booking.Note,
          addons: this.bookingData.booking.AddedProducts,
          //Online booking or not
          webBooking: webBooking,
          //Customer current coordinated when creating the booking.
          bookingLat: currentCoordinates ? currentCoordinates.Latitude : null,
          bookingLng: currentCoordinates ? currentCoordinates.Longitude : null
        });
      if (result.AggregatedResult == BookingErrorCode.Valid) {
        this.bookingData.booking.Id = result.BookingId;
        this.bookingData.booking = await this.Api.get<RentalInfo>("/Rental/GetRentalInfo", { bookingId: result.BookingId });
      }
      
      //this.UI.loaderCompleted(token);

      //if (result.AggregatedResult == BookingErrorCode.Valid) {
      //  await this.Api.put<BookingValidation>("/Rental/SetAddons", null,
      //    {
      //      rentalId: this.bookingData.booking.Id,
      //      products: this.bookingData.booking.AddedProducts,
      //    }
      //  );
      //}

      return result;
    }
    //If existing booking
    else {
      var token = this.UI.beginLoading("Intellitrailer.Booking.Payment.msgLoadingOptionsUpdate", "Updating booking", null);
      var result: BookingValidation = await this.Api.put<BookingValidation>("Rental/ChangeBooking", null, {
        rentalId: +this.bookingData.existingBooking,
        pickupTime: this.bookingData.booking.PickupTime.toISOString(),
        returnTime: this.bookingData.booking.ReturnTime.toISOString(),
        pickupLocation: this.bookingData.booking.PickupLocation.Id,
        returnLocation: this.bookingData.booking.PickupLocation.Id,
        typeId: this.bookingData.booking.PrimaryObjectTypeId
      });
      this.UI.loaderCompleted(token);

      if (result.AggregatedResult == BookingErrorCode.Valid) {
        await this.Api.put<BookingValidation>("/Rental/SetAddons", null,
          {
            rentalId: +this.bookingData.existingBooking,
            products: this.bookingData.booking.AddedProducts,
          }
        );
        this.bookingData.booking.Id = +this.bookingData.existingBooking;
      }

      return result;
    }
  }

  public async getOptions(location: LocationInfo, ignoreTimeLimit: boolean = false): Promise<TypeGroup[]> {
      let typesInCategory:ObjectTypeInfo[] = await this.Api.get<ObjectTypeInfo[]>("RentalObject/GetAvailableTypes",
        {
          locationId: location.Id,
          categoryId: null,
          pickupTime:
            (!this.bookingData.booking.PickupTime && this.bookingData.atLocation ? moment().toISOString() :
              this.bookingData.booking.PickupTime.clone().toISOString()),
          returnTime: this.bookingData.booking.ReturnTime.clone().toISOString(),
          bookingId: this.bookingData.booking && this.bookingData.booking.Id ? this.bookingData.booking.Id : null
        });

      let group: TypeGroup = { Category: null, Options: await this.filterAndConvertToBookings(typesInCategory, location) };
      let bookingOptions: TypeGroup[] = [];
      if (this.bookingData.objectCategories && this.bookingData.objectCategories.length > 0) {
        bookingOptions.unshift(group);
      }
      else if (group.Options.length > 0) {
        bookingOptions.push(group);
      }

      this.loading = false;
      return bookingOptions;
  }

  public async filterAndConvertToBookings(availableTypes: ObjectTypeInfo[], location: LocationInfo = null, pickupTime: moment.Moment = null, returnTime: moment.Moment = null): Promise<RentalInfo[]> {

    var bookingOptions: RentalInfo[] = [];
    availableTypes.forEach((type) => {
      var bookingOption: RentalInfo = new RentalInfo();
      bookingOption.PickupLocation = location ? location : this.bookingData.booking.PickupLocation;
      bookingOption.PickupLocationId = location ? location.Id : this.bookingData.booking.PickupLocation.Id;
      bookingOption.PickupTime = pickupTime ? pickupTime : this.bookingData.booking.PickupTime;
      bookingOption.ReturnTime = returnTime ? returnTime : this.bookingData.booking.ReturnTime;
      bookingOption.PrimaryType = type;
      bookingOption.PrimaryObjectTypeId = type.Id;
      bookingOption.DiscountId = this.bookingData.booking.DiscountId;

      if (!bookingOptions.find(x => x.PrimaryObjectTypeId == type.Id)) bookingOptions.push(bookingOption);
    });

    return bookingOptions;
  }

  public async getCategories(): Promise<TypeCategoryInfo[]> {
    if (this.preLoadedCategories) return this.preLoadedCategories;
    else {
      //var token = this.UI.beginLoading("Intellitrailer.Booking.Preloader.General", "Preparing view", null);
      this.preLoadedCategories = await this.Api.get<TypeCategoryInfo[]>("/RentalObject/GetObjectFamilies");
      //this.UI.loaderCompleted(token);

      this.preLoadedCategories.sort((x, y) => x.SortOrder < y.SortOrder ? -1 : 1);

      return this.preLoadedCategories;
    }
  }

  public fixDistance(distance: number): string {
    if (distance < 1) {
      var distanceMeters = distance * 1000;
      return +distanceMeters.toFixed(0) + " m";
    }

    return +distance.toFixed(1) + " km";
  }

  public async redirectToPayment(rentalId: number, returnPath: String) {
    let paymentURI = await this.Api.get("Rental/GetPaymentURI", { rentalId: rentalId });
    let returnUri = `/${returnPath}`;
    returnUri = returnUri.replace("%2F", "/").replace("%2F", "/");
    returnUri = returnUri.replace("//", "/");

    var base = '';
    if (window['baseUri']) base = window['baseUri'];
    returnUri = window.location.origin + base + returnUri;

    let scheme = this.appSettings.appScheme ?? "";

    let paymentUrl = paymentURI + `?returnuri=${returnUri}&appScheme=${scheme}`;
    document.location.href = paymentUrl;
  }
}

export class BookingQuery {
  public PickupTime?: Date;
  public ReturnTime?: Date;

  public PickupLocationId?: number;
  public ReturnLocationId?: number;

  public ObjectTypeId?: number;

  public Customer: CustomerInfo = new CustomerInfo();
}
