<app-view-title [showBack]="false" Title="{{ 'Intellitrailer.Booking.ConfirmedBooking.TitleBookingConfirmation' | translate : 'Booking confirmation' }}"></app-view-title>


<div class="view-content-full">
  <app-booking-summary *ngIf="booking && this.booking.PrimaryType" [(booking)]="booking" [confirmed]="true"></app-booking-summary>
  <div style="height: 90px"></div>
</div>
<div class="view-actions white-fade-out-bg">
  <button  *ngIf="appSettings.appType == 'app'" mat-raised-button (click)="next()">{{ 'Intellitrailer.Navigation.ShowMyBookings' | translate : 'Show my bookings' }}</button>
  <button style="position: absolute; bottom: calc(0px - env(safe-area-inset-bottom, 0px));"  *ngIf="appSettings.appType == 'web'" mat-raised-button (click)="next()">{{ 'Intellitrailer.Navigation.ReturnStart' | translate : 'Go back to Start' }}</button>
</div>

