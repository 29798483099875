import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';
import { InsightsService } from 't4core';

@Injectable()
export class MonitorInterceptor implements HttpInterceptor {

  constructor(private insights: InsightsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen(error => {
        var count = 0;
        return error.pipe(
          concatMap((error, count) => {
            if (count <= 3 && (error.status == 0 || error.status == undefined)) {
              this.insights.logEvent("Retry http: " + JSON.stringify(request));

              count++;
              return of(error);
            }

            return throwError(error);
          }),
          delay(3000)
        )
      })
    );
  }
}
