import { Component, ElementRef } from '@angular/core';
import { BookingService, NavigationService, TimeStep } from 'app-components';
import * as moment from 'moment';
import { AppSettingsService } from 't4-app-integration';
import { OpenHours, PageBase, RentalInfo, TimeSlot, TypeCategoryInfo, UIService } from 't4core';


@Component({
  selector: 'date-and-time-component',
  templateUrl: './date-and-time.component.html'
})

export class DateAndTime extends PageBase {
  public booking: RentalInfo;
  private atLocation: boolean = false;

  public pickupDate: Date = new Date();
  public returnDate: Date = new Date();
  public pickupNow: boolean = false;

  public _pickupTime: moment.Moment = moment();
  public _returnTime: moment.Moment = moment();

  public minReturn: moment.Moment = moment().startOf('day');
  public maxReturn: moment.Moment = moment().endOf('day');

  public pickupOpens: moment.Moment;
  public pickupCloses: moment.Moment = moment(new Date());
  private returnOpens: moment.Moment;
  private returnCloses: moment.Moment = moment(new Date());

  public pickupClosed: boolean = false;
  public returnClosed: boolean = false;

  public pickupSlots: TimeSlot[] = [];
  public returnSlots: TimeSlot[] = [];
  public minPickup: moment.Moment = moment().startOf('day');
  public maxPickup: moment.Moment = moment().endOf('day');
  public timePickerLoading: boolean = false;
  private timeStep: TimeStep = { step: 15, unit: 'minutes' };

  public isValid: boolean = false;

  public noChargeHoures: boolean = false;

  public within30m: boolean = false;
  public gpsBasedBookingFee: boolean = false;

  public selectedCategory: TypeCategoryInfo;


  public limitExist: boolean = false;
  public limit: number;
  public limitMinReturnMargin: number = 0;
  public staticMaxReturnTime: number = 0;
  public theme: string;
  public redirectFromNearByoptions: string;
  constructor(
    public UI: UIService,
    el: ElementRef,
    private navigator: NavigationService,
    private bookingSvc: BookingService,
    private appSettings: AppSettingsService,
  ) {
    super(el);
    this.redirectFromNearByoptions = this.navigator.getParam("redirectFromNearByoptions");
  }

  public back() {
    this.navigator.back();
  }

  public isSameDate(): boolean {
    // If closes and opens are both at hour zero we consider it open all night and do not show message.
    if (this.returnOpens && this.pickupCloses && this.returnOpens.hour() == 0 && this.pickupCloses.hour() == 0) return true;

    // If pickup and return are on different dates
    return (this._pickupTime.toDate().getFullYear() == this._returnTime.toDate().getFullYear()) &&
      (this._pickupTime.toDate().getMonth() == this._returnTime.toDate().getMonth()) &&
      (this._pickupTime.toDate().getDate() == this._returnTime.toDate().getDate());
  }

  public compareDates(date1: Date, date2: Date): boolean {
    // Extract month and day from the first date
    const month1 = date1.getMonth(); // Months are zero-indexed (0-11)
    const day1 = date1.getDate();

    // Extract month and day from the second date
    const month2 = date2.getMonth(); // Months are zero-indexed (0-11)
    const day2 = date2.getDate();

    // Compare month and day
    return month1 === month2 && day1 === day2;
  }


  async ngOnInit() {
    var state = this.bookingSvc.getBookingState();
    if (state.objectCategory) this.selectedCategory = state.objectCategory;

    // SPECIAL CASE //
    // if a Varebil is Selected we set the max limit to 3 hours.
    if (this.selectedCategory && (this.selectedCategory.Id.toUpperCase() == 'D8545BC8-4300-42BB-9FA1-CFAF817DA3D0')) {
      this.limitExist = true;
      this.limit = 3;
      this.limitMinReturnMargin = -180;
      this.staticMaxReturnTime = 19;

    }

    this.booking = state.booking;
    this.bookingSvc.getPriceInfo().then(x => {
      var y = x as any;
      if (y) this.gpsBasedBookingFee = y.UsesGpsBasedBookingFee;
    });
    this.atLocation = state.atLocation;
    this.within30m = this._pickupTime < moment().add(30, 'minutes') && this.appSettings.appType == "app";
    this.theme = this.appSettings.settings.theme || 'Default';

    if (this.redirectFromNearByoptions == "true") {
      if (this.selectedCategory) await this.getTimeSlotForCategory();
      else await this.getFreeTimeSlots();
    }
    else await this.getFreeTimeSlots();

    // If a pickup date has already been set we use that otherwise we initialize a new one
    if (this.booking.PickupTime) {
      this.setPickupTime(this.booking.PickupTime);
      this.pickupDate = this.booking.PickupTime.toDate();
    } else {

      // If customer is on site we assume he wants to pick it up now otherwise in an hour
      if (this.atLocation) {
        this.setPickupTime(this.roundToTimeStep(moment(), true));
        this.pickupNow = true;
      } else {
        this.setPickupTime(this.roundToTimeStep(moment().add(60, 'minutes'), true));
      }
      this.pickupDate = this._pickupTime.toDate();
    }

    //Check if user has saved a returnTime, otherwise set to today
    if (this.booking.ReturnTime) {
      this.setReturnTime(this.booking.ReturnTime);
    }
    else {
      if (this.limitExist && this.limit && this.limit > 0) {
        this.setReturnTime(this._pickupTime.clone().add(this.limit, 'hour'))
      }
      else if (this.Settings.clientSettings.Bookings.DefaultBookingLength) {
        this.setReturnTime(this._pickupTime.clone().add(this.Settings.clientSettings.Bookings.DefaultBookingLength, 'hour'));
      }
      else {
        this.setReturnTime(this._pickupTime.clone().add(3, 'hour'));
      }
    }

    if (this.redirectFromNearByoptions == "true" && this.selectedCategory && this.pickupDate != this.returnDate) await this.getTimeSlotForCategory();

    this.pickupNow = false;
    await this.reloadRestrictions();
  }

  // Change the ate of the calendar
  public async setPickupDate(pDate: Date, incrementDate: number = null) {
    if (this.isSameDay(this.pickupDate, this.today.toDate()) && incrementDate && incrementDate < 0) {
      return;
    }

    if (pDate) {
      this.pickupDate = moment(pDate).clone().toDate();
    }
    else if (incrementDate > 0 || (!this.isSameDay(this.pickupDate, this.today.toDate()) && incrementDate < 0)) {
      var newDate = moment(this.pickupDate);
      newDate.add(incrementDate, 'day');
      this.pickupDate = newDate.clone().toDate();
    }

    //PickupDate can not be later than returnDate
    if (this.pickupDate > this.returnDate) {
      await this.setReturnDate(this.pickupDate);
    }

    await this.reloadRestrictions();
  }

  // Change the date of the calendar component
  public async setReturnDate(rDate: Date, incrementDate: number = null) {
    var minReturnCloned = this.minReturn.clone().toDate();
    var minPickupCloned = this.minPickup.clone().toDate();

    if (
      incrementDate && incrementDate < 0 // There exist a increase, and its less then 0.
      &&
      (this.isSameDay(this.returnDate, this.today.toDate()) || // So we cant pick a date less then today as return.
        this.compareDates(minReturnCloned, minPickupCloned)) // So we cant pick a day that is less then minium pickup date.
    ) {
      return;
    }

    if (rDate) {
      this.returnDate = moment(rDate).clone().toDate();
    }
    else if (incrementDate > 0 || (!this.isSameDay(this.returnDate, this.today.toDate()) && incrementDate < 0)) {
      var newDate = moment(this.returnDate);
      newDate.add(incrementDate, 'day');
      this.returnDate = newDate.clone().toDate();
    }

    //PickupDate can not be later than returnDate
    //if (this.pickupDate > this.returnDate) {
    //  await this.setPickupDate(this.returnDate);
    //}

    await this.reloadRestrictions();
  }

  // Set the pickup time of the booking
  public async setPickupTime(pTime: moment.Moment) {
    if (!pTime /*|| pTime.isSame(this._pickupTime)*/) return;

    var oldValue = this._pickupTime?.clone();
    this._pickupTime = pTime.clone();

    if (!this.isSameDay(this._pickupTime.toDate(), this.pickupDate)) {
      this.setPickupDate(this._pickupTime.toDate());
    }

    this.minReturn = this.getMinReturn();
    this.maxReturn = this.getMaxReturn();

    //If we Select the same day on the returnTime, in case we have a colliding booking we copy over the slots and then check it in the collide.
    //Only when we have a Selectecd Gategory.
    if (this.redirectFromNearByoptions == "true" && this.selectedCategory && this.isSameDay(pTime.toDate(), this._returnTime.toDate())) {
      //If we have a Limit, we set the max time to that.
      if (this.limitExist && this.limit && this.limit > 0) {
        const returnSlots = this.pickupSlots.map(slot => ({
          ...slot,
          To: this._pickupTime.clone().add(this.limit, 'hours')
        }));
        this.returnSlots = returnSlots;
      }
      else this.returnSlots = this.pickupSlots.map(slot => ({ ...slot }));
    }

    if (this.redirectFromNearByoptions == "true" && this.selectedCategory)
      this.checkCollidingBookings();

    if (this.appSettings.appType == "app") {
      setTimeout(async () => {
        this.scrolltoBottom();
      }, 50);
    }

    if (this._returnTime) {
      if (oldValue) {
        // If retrun time is set and pickup was changed: Move return time to preserve the booking length
        var diff = this._returnTime.toDate().getTime() - oldValue.toDate().getTime();
        this.setReturnTime(this._pickupTime.clone().add(diff, 'milliseconds'));
      }
    } else {
      // If return time is not still set: Set it to default length
      this.setReturnTime(this._pickupTime.clone().add(this.Settings.clientSettings.Bookings.DefaultBookingLength, 'hour'));
    }

    this.validate();
    this.within30m = this._pickupTime < moment().add(30, 'minutes');
  }

public setReturnTime(rTime: moment.Moment) {
  if (!rTime || rTime.isSame(this._returnTime)) return; // Skip if no change

  if (!rTime) {
    this._returnTime = null;
    return;
  }

  // Update `returnDate` to match the new day of `rTime`, if they differ
  if (!this.isSameDay(this.returnDate, rTime.toDate())) {
    this.setReturnDate(rTime.toDate()); // Adjust returnDate to match the day of rTime
  }

  // Validate if `rTime` is within allowed time slots
  const isWithinTimeSlots = this.isTimeWithinTimeSlotsReturn(rTime);

  if (!isWithinTimeSlots) {
    this.adjustReturnTimeToEarliestSlot(rTime); // Adjust to a valid slot if necessary
  } else {
    this._returnTime = rTime.clone(); // Save the valid rTime
  }

  this.validate(); // Re-validate the booking
}


  private isTimeWithinTimeSlotsReturn(time: moment.Moment): boolean {
    // Iterate/loop through available time slots and check if the provided time falls within any of them.

    if (!this.returnSlots || !this.returnSlots[0]) {
      return false;
    }

    for (const slot of this.returnSlots) {
      if (time.isBetween(slot.From, slot.To)) {
        return true;
      }
    }
    return false;
  }



  private adjustReturnTimeToEarliestSlot(time: moment.Moment): void {
    // Find the earliest available time slot.
    let earliestSlot: moment.Moment | null = null;
    for (const slot of this.returnSlots) {
      if (!slot) continue;

      if (!earliestSlot || slot.To.isBefore(earliestSlot)) {
        earliestSlot = slot.To.clone();
      }
    }
    // If an earliest slot is found, set the return time to it.
    if (earliestSlot) {
      this._returnTime = earliestSlot.clone();

      if (!this.isSameDay(this._returnTime.toDate(), this.returnDate)) {
        this.setReturnDate(this._returnTime.toDate());
      }
    }
  }


  public async getTimeSlotForCategory() {
    if (!this.selectedCategory) return;

    var openHoursForType = await this.Api.get<TimeSlot[]>("/Rental/GetFreeTimeSlots", {
      locationId: this.booking.PickupLocationId,
      categoryId: this.selectedCategory.Id,
      startTime: moment(this.pickupDate).clone().startOf('day'),
      endTime: moment(this.returnDate).clone().endOf('day')
    })
    //Removes the Timezone from the object so it matches.
    const timeZoneOffset = moment().format('Z');
    const hours = Number(timeZoneOffset.substring(1, 3));

    if (openHoursForType) {
      openHoursForType.forEach(slot => {
        slot.From.subtract(hours, 'hours');
        slot.To.subtract(hours, 'hours');
      })
    }

    //Clones.
    this.pickupSlots = openHoursForType.map(slot => ({ ...slot }));

    if (this.limitExist && this.limit && this.limit > 0) {
      const returnSlots = openHoursForType.map(slot => ({
        ...slot,
        To: this._pickupTime.clone().add(this.limit, 'hours')
      }));
      this.returnSlots = returnSlots;
    }
    else this.returnSlots = openHoursForType.map(slot => ({ ...slot }));

  }

  public async updateOpenHours() {
    if (!this.booking.PickupLocation) {
      return;
    }

    var openHours: OpenHours[] = await this.Api.get<OpenHours[]>("/Location/GetOpenHoursForPeriod", {
      locationId: this.booking.PickupLocation.Id,
      startDate: moment(this.pickupDate).toISOString(true),
      endDate: moment(this.returnDate).add('days', 1).toISOString(true)
    });

    if (!openHours.length) return;

    var pickup = openHours.find(x => x.Date.clone().startOf("day").isSame(moment(this.pickupDate).clone().startOf('day')));

    if (pickup) {
      this.pickupOpens = moment(pickup.Opens.clone().utc().format("YYYY-MM-DD HH:mm:ss.SSS"));
      this.pickupCloses = moment(pickup.Closes.clone().utc().format("YYYY-MM-DD HH:mm:ss.SSS"));
      this.pickupClosed = pickup.IsClosed;
    }

    // Different return location? Get thos hours
    if (this.booking.ReturnLocation && this.booking.ReturnLocation.Id != this.booking.PickupLocation.Id) {
      openHours = await this.Api.get<OpenHours[]>("/Location/GetOpenHoursForPeriod", {
        locationId: this.booking.ReturnLocation.Id,
        startDate: moment(this.returnDate).toISOString(true),
        endDate: moment(this.returnDate).add('days', 1).toISOString(true)
      });
    }

    var returnT = openHours.find(x => x.Date.clone().startOf("day").isSame(moment(this.returnDate).clone().startOf('day')));
    if (returnT) {
      this.returnOpens = moment(returnT.Opens.clone().utc().format("YYYY-MM-DD HH:mm:ss.SSS"));
      this.returnCloses = moment(returnT.Closes.clone().utc().format("YYYY-MM-DD HH:mm:ss.SSS"));
      this.returnClosed = returnT.IsClosed;
    }

    //Disable pickup now outside open hours
    var now = moment();
    if (this.pickupNow && this.pickupOpens >= now || this.pickupCloses <= now) {
      this.pickupNow = false;
    }
  }

  public isSameDay(firstDate: Date, secondDate: Date): boolean {
    return moment(firstDate).format("YYYY-MM-DD") === moment(secondDate).format("YYYY-MM-DD");
  }

  public scrolltoBottom() {
    if (this.appSettings.appType != "app" || !document.getElementById('bottomDiv')) return;

    document.getElementById('bottomDiv').scrollIntoView({ behavior: "smooth" });
  }

  public toggleTab(number: number) {
    if (number == 1) this.pickupNow = false;
    else if (number == 0) {
      this.pickupNow = true;
    }
  }

  public async next() {
    if (!this.isValid) return;

    while (this.bookingSvc.loading)
      await new Promise(resolve => setTimeout(resolve, 100));


    this.booking.PickupTime = this._pickupTime.clone();
    this.booking.ReturnTime = this._returnTime.clone();
    this.bookingSvc.saveBookingState(this.booking);

    this.navigator.executeCommand("Next");
  }

  private roundToTimeStep(timeStep: moment.Moment, goBackward: boolean = true): moment.Moment {
    timeStep.seconds(0);
    var step = 1;
    var quarters: number[] = [0, 15, 30, 45, 60];
    for (var i = 0; i < 6; i += step) {
      if (i < 4 && quarters[i] <= timeStep.minutes() && timeStep.minutes() < quarters[i + step]) {
        timeStep.minutes(quarters[i]);
        break;
      }
      else if (i == 4) {
        timeStep.minutes(quarters[i]);
      }
    }

    //Round to nearest timeStep backwards
    if (goBackward) {
      timeStep.subtract(1, 'seconds');
    }
    return timeStep.millisecond(0);
  }

  private async reloadRestrictions() {
    this.timePickerLoading = true;
    await this.updateOpenHours();
    this.minPickup = this.getMinPickup();
    this.maxPickup = this.getMaxPickup();
    this.minReturn = this.getMinReturn();
    this.maxReturn = this.getMaxReturn();

    if (this.redirectFromNearByoptions == "true") {
      if (this.selectedCategory) await this.getTimeSlotForCategory();
      else await this.getFreeTimeSlots();
    }
    else await this.getFreeTimeSlots();
   
    this.timePickerLoading = false;

    this.validate();
  }

  private getMinPickup(): moment.Moment {
    let now = this.roundToTimeStep(moment(), true);
    if (this.pickupOpens && this.pickupOpens < now && this.pickupDate.toDateString() == now.clone().toDate().toDateString()) {
      return now.clone();
    }
    if (this.pickupOpens) {
      return this.pickupOpens.clone();
    }
    return now.clone().startOf('day');
  }

  private getMinReturn(): moment.Moment {
    var min;
    if (this._pickupTime && (!min || this._pickupTime.toDate().getTime() > min.toDate().getTime())) min = this._pickupTime.clone().add(1, 'hour');
    if (this.returnOpens && (!min || this.returnOpens.toDate().getTime() > min.toDate().getTime())) min = this.returnOpens.clone();
    if (!min || this.roundToTimeStep(moment()).add(1, 'hour').toDate().getTime() > min.toDate().getTime()) min = this.roundToTimeStep(moment()).add(1, 'hour');

    return min;
  }

  private getMaxPickup(): moment.Moment {
    if (!this.pickupCloses) {
      return moment(this.pickupDate).clone().endOf('day');
    }

    if (!this.isSameDay(this.pickupCloses.toDate(), this.pickupDate)) {
      return this.pickupCloses.clone().add('second', -1);
    } else {
      return this.pickupCloses.clone();
    }
  }

  private getMaxReturn(): moment.Moment {
    let step: number = 15;

    if (!this.returnCloses) {
      return moment(this.returnDate).clone().endOf('day').subtract(step, 'minutes');
    }

    if (!this.isSameDay(this.returnCloses.toDate(), this.returnDate)) {
      return this.returnCloses.clone().add('second', -1);
    } else {
      if (this.staticMaxReturnTime)
        return moment(this.returnDate).clone().startOf('day').add(this.staticMaxReturnTime, 'hour');
      else
        return this.returnCloses.clone().subtract(this.limitMinReturnMargin, 'minutes'); // This does that we cant Use the ½ hour of the opening time, as we remove the last 15 min.
    }
  }

  private async getFreeTimeSlots() {
    const daysToGenerate = 2; // Number of days to generate slots for.
    const pickupDateStart = moment(this.pickupDate).startOf('day');
    const returnDateStart = moment(this.returnDate).startOf('day');

    // Generate pickup slots for multiple days
    this.pickupSlots = [];
    for (let i = 0; i < daysToGenerate; i++) {
      const day = pickupDateStart.clone().add(i, 'days');
      this.pickupSlots.push({
        From: day.clone().startOf('day'),
        To: day.clone().endOf('day').subtract(this.timeStep.step, this.timeStep.unit),
        SlotNo: i + 1,
        Date: day.clone().startOf('day'),
      });
    }

    // Generate return slots for multiple days
    this.returnSlots = [];
    for (let i = 0; i < daysToGenerate; i++) {
      const day = returnDateStart.clone().add(i, 'days');
      this.returnSlots.push({
        From: day.clone().startOf('day'),
        To: day.clone().endOf('day').subtract(this.timeStep.step, this.timeStep.unit),
        SlotNo: i + 1,
        Date: day.clone().startOf('day'),
      });
    }

    return;
  }


  private convertToPickupSlots(timeSlots: TimeSlot[]): TimeSlot[] {
    return timeSlots.map(slot => ({
      From: slot.From.clone()
        .add(-slot.From.utcOffset(), 'minute'),
      To: slot.To.clone()
        .add(-slot.To.utcOffset(), 'minute')
        .subtract(this.timeStep.step, this.timeStep.unit),
      SlotNo: slot.SlotNo,
      Date: slot.Date
    }));
  }

  private convertToReturnSlots(timeSlots: TimeSlot[]): TimeSlot[] {
    return timeSlots.map(slot => ({
      From: slot.From.clone()
        .add(-slot.From.utcOffset(), 'minute')
        .add(this.timeStep.step, this.timeStep.unit),
      To: slot.To.clone()
        .add(-slot.To.utcOffset(), 'minute'),
      SlotNo: slot.SlotNo,
      Date: slot.Date
    }));
  }

  private checkCollidingBookings(): void {
    //Check if there is any colliding booking
    let isCollission: boolean = !this.returnSlots.find(
      x =>
        x.From.clone().subtract(this.timeStep.step, this.timeStep.unit) <= this._pickupTime &&
        x.To == moment(this.returnDate).clone().endOf('day')
    );
    if (!isCollission) {
      return;
    }
    //Get available time before colliding booking
    var lastTimeWithoutCollision: TimeSlot;
    for (let slot of this.returnSlots) {
      let isTimeWithoutCollission: boolean =
        (!lastTimeWithoutCollision && slot.From <= this._pickupTime.clone().subtract(this.timeStep.step, this.timeStep.unit)) ||
        (slot.From.clone().subtract(this.timeStep.step, this.timeStep.unit) <= this._pickupTime && slot.To > lastTimeWithoutCollision.To);
      if (isTimeWithoutCollission) {
        lastTimeWithoutCollision = slot;
      }
    }

    //Set available return times to before colliding booking
    this.returnSlots = [lastTimeWithoutCollision];
  }

  private convertDateToMoment(date: Date, moment: moment.Moment): moment.Moment {
    if (moment) {
      moment.year(date.getFullYear());
      moment.month(date.getMonth());
      moment.date(date.getDate());
    }
    return moment;
  }

  private validate() {
    if (
      (this._pickupTime >= this.minPickup && this._pickupTime <= this.maxPickup) &&
      (this._returnTime >= this.minReturn && this._returnTime <= this.maxReturn) &&
      (this._pickupTime < this._returnTime)
    ) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }
}
