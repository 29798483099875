import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import * as uuid from 'uuid';
import { ApiService, AuthService, CustomerInfo, LocalStorage, SettingsService, TranslationService, InsightsService } from 't4core';

import { Coordinates } from '../../models/coordinates';
import { LocationInfo } from 't4core';
import { App, AppType, AppTypeSettings, Web } from '../../models/app-type-settings';
import { AppSettings } from '../../models/app-settings';


@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  public contextId: string = "";
  public appType: AppType = "app";
  public overrideRoutes: boolean;
  public privateKey: string = "";
  public appVersion: string = "";
  public appScheme: string = null;
  public clientVersion: string;
  public originalHref: string = null;
  public localLocationId: number = 0;
  public params: any = {};
  public language: string = "";
  private anticipatedCountry: string = "";

  public customerSupportLanguage: string = "";

  static getComponents(components: any) {
    this.components = components;
  }

  public static components: { [key: string]: any } = {}

  //Previously restarted app in this runtime
  public previouslyRestarted: string;

  //Saved data
  public userLoggedIn: boolean = false;
  public userId: string;
  public customer: CustomerInfo;
  public userDetails: any;

  //General
  public settings: AppSettings = {};
  public typeSettings: AppTypeSettings = App;

  private isSettingsLoaded: boolean = false;
  private isAuthenticated: boolean = false;
  //Custom members in the menu  

  constructor(
    private Api: ApiService,
    private auth: AuthService,
    private localStorage: LocalStorage,
    private translate: TranslationService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private insights: InsightsService
  ) {
    this.unpack();

    // Inititialize parameters
    this.initParams();
  }

  // ***** Initialization *****
  // Initiate app parameters from querystring, context page or local storage
  private initParams() {
    // Gets all the params as a key/pair object.
    var queryParams = new URLSearchParams(window.location.search);

    //Loop through the queryParams, where it find all the params from our QueryString and adds it to our variable params.
    queryParams.forEach((value: string, key: string) => {
      this.params[key] = value;
    });

    // Load all basic parameters
    if (this.getParam("clear") == "true") {
      this.clear();
      window.location.href = "https://" + window.location.host;
    } else {
      if (this.getParam("appVersion")) this.appVersion = this.getParam("appVersion");
      if (this.getParam("appScheme")) this.appScheme = this.getParam("appScheme");
      if (this.getParam("externalId")) this.contextId = this.getParam("externalId");
      if (this.getParam("privateKey")) this.privateKey = this.getParam("privateKey");
      if (this.getParam("lId")) this.localLocationId = parseInt(this.getParam("lId"));
      if (this.getParam("language")) this.language = this.getParam("language");

      // Loade app mode and the corresponding settingsd (App/Web)
      this.appType = (this.getParam("appType") ?? "app") as AppType;
      this.typeSettings = (this.appType == 'web') ? Web : App;;

      // Determine initial language
      if (!this.language) this.language = this.getLanguage();

      // Is user not set but was an input parameter
      // Set user based on input
      if (!this.userId) {
        var userId = this.getParam("userId")
        if (userId) this.userId = userId;
      }

      this.pack();
    }

    this.originalHref = window.location.href;
  }

  public async initApp() {
    // Add context data to insights
    this.insights.setProperty("Context", this.contextId);
    this.insights.setProperty("Scheme", this.appScheme);
    this.insights.setProperty("Mode", this.appType);
    this.insights.setProperty("SubContext", this.getParam("SubContext"));

    var userId = this.userId;
    await this.auth.Authenticate("", this.contextId, this.privateKey, userId);
    this.applyTheme();
    this.setUser(this.userId, this.userDetails, this.customer);
    // Apply language settings
    

      await this.setLanguage(this.language);
      this.translate.load();

    this.isAuthenticated = true;
  }

  // ***** Theme handling ***
  private async applyTheme() {
    if (this.isSettingsLoaded) return;

    // Load settings string either from context page or api
    var parsedSettings = this.getContextParam("themeSettings");
    if (!parsedSettings) {
      var settings = await this.Api.get<any>("Settings/GetSettingAsString", { key: "IntellitrailerAppSettings" });
      parsedSettings = JSON.parse(settings);
    }

    // Parse settings
    

    if (parsedSettings) {
      this.settings = parsedSettings;

      // Override theme with parameter if present
      if (this.getParam("theme")) {
        this.settings.inheritedTheme = this.settings.theme;
        this.settings.theme = this.getParam("theme");
      }

      // Import css (If not loaded by the context page)
      if (this.settings.theme && this.getParam("isThemeCssLoaded") !== "true") {
        document.getElementById('stylesheet').setAttribute('href', '/Styles/' + this.settings.theme + "/Style.css?" + this.clientVersion);
      }
    }

    // Store in memory and return
    this.isSettingsLoaded = true;
  }

  // Download a file from the theme
  public async getThemeFile(filename: string): Promise<string> {
    var response = await fetch(`/Styles/${this.settings.theme}/${filename}?v=${this.clientVersion}`);

    // If 404 and has an inherited theme. Check that!
    if (response.status == 404 && this.settings.inheritedTheme)
      response = await fetch(`/Styles/${this.settings.inheritedTheme}/${filename}?v=${this.clientVersion}`);

    // Iff HttpStatus.Ok: return the content of the file
    if (response.status == 200) {
      var data = await response.text();
      return data;
    } else {
      // Throw error to make sure the error is logged
      throw new Error(`No ${filename} was found for theme ${this.settings.theme}`);
    }
  }


  // ***** Local storage management *****
  public clear() {
    this.localStorage.remove("viewState");
  }

  public pack() {
    var data: any = {};
    data.contextId = this.contextId;
    data.key       = this.privateKey;
    data.scheme    = this.appScheme;
    data.type      = this.appType;
    data.version   = this.appVersion;
    data.localId   = this.localLocationId;
    data.language = this.language;

    data.other = this.params;

    // Store user id only in app mode
    if (this.typeSettings.isStoringCustomer) {
      data.userId = this.userId;
      data.userInfo = this.userDetails;
      data.userCustomer = this.customer;
    }


    var payload = btoa(JSON.stringify(data));

    this.localStorage.set("viewState", payload);
  }

  public unpack() {
    try {
      var payload = atob(this.localStorage.get("viewState"));
      if (!payload) return;
    } catch (ex) {
      return;
    }

    var data = JSON.parse(payload);
    if (!data) return;

    this.contextId = data.contextId;
    this.privateKey = data.key;
    this.appScheme = data.scheme;
    this.appType = data.type;
    this.appVersion = data.version;
    this.localLocationId = data.localId;

    //After we have únpacked everything, we set the language.
      //And if we dont have a language, we get the brower language.
    this.language = data.language;
    if (!this.language)
      this.language = this.getLanguage();
    this.setLanguage(this.language);

    if (data.other)
      this.params = data.other;

    this.userId = data.userId;
    this.userDetails = data.userInfo;
    this.customer = data.userCustomer;
  }

  // ***** Parameter retrieval *****
  public getParam(param: string): string | null {
    //Check context page settings
    if (window["params"] && window["params"][param]) return window["params"][param].toString();

    // Check query string
    return this.params[param];
  }

  // Retrive a parameter from the context page
  public getContextParam(param: string): any {
    if (window["params"] && window["params"][param]) {
      return window["params"][param];
    }

    return null;
  }


  // ***** Language handling *****

  public getLanguage() {
    if (this.language) 
      return this.language;
    else 
      return AppSettingsService.GetBrowserLanguage();
  }
  public async setLanguage(language: string) {
    this.language = language;
    this.params["language"] = undefined;
    this.pack();

    this.translate.changeLocale(language);
    this.translate.changeLanguage(language);
    this.translate.setPreferedLanguage(language);
    this.translate.languageChanged.emit();

    // If user is logged in, save their settings on the server
    if (this.userLoggedIn)
      await this.Api.get<boolean>("Settings/ChangeLanguage", { language: this.language });
  }






  ////////////////////////////////////
  // ***** Device information ***** //
  ////////////////////////////////////

  public async waitForAuthentication(): Promise<any> {
    while (!this.isAuthenticated) await new Promise(resolve => setTimeout(resolve, 100));
    return true;
  }

 
  /// Check if the server has been updated with a new version
  public async getClientVersion(onload: boolean = false): Promise<[string, boolean]> {

    var id = uuid.v4();

    if (onload) {
      var repsonse = await fetch('/Turbo.Client.Version.txt?v=' + id);
      var data = await repsonse.text();
      if (window["appVersion"] && window["appVersion"] !== data.trim()) {
        window.location.href = this.originalHref + '&q=' + id;
      } else {
        this.clientVersion = data;
      }
    }
    else {
      var latestVersion: string;
      var isLatestVersion: boolean;

      var repsonse = await fetch('/Turbo.Client.Version.txt?v=' + id);
      var data = await repsonse.text();
      latestVersion = data;

      if (latestVersion != this.clientVersion) {
        isLatestVersion = false;
      }
      else {
        isLatestVersion = true;
      }
    }
    return [this.clientVersion, isLatestVersion];
  }

  // Checks the version of the native client to make sure its greater than or equeal to a given version
  // Used to make sure that different features are available
  public meetsMinimumAppVersion(version: number): boolean {
    if (this.appVersion && this.appVersion != "null") {
      return +this.appVersion >= version;
    }
    return true;
  }

  // Find the language of the local browser
  public static GetBrowserLanguage(): string {
    var availableLanguages = ['sv', 'en', 'da', 'no', 'de', 'pl'];
    var browserLang = navigator.language;
    if (browserLang.indexOf("-") > 0) browserLang = browserLang.substring(0, browserLang.indexOf("-"));
    if (browserLang == "nb") browserLang = 'no';
    if (browserLang == "nn") browserLang = 'no';

    if (availableLanguages.indexOf(browserLang) >= 0) return browserLang;

    return 'en';
  }

  /// Get information on the device used to run he app. (iOS/Android)
  public static getDeviceType(): string {
    var win: any = window;
    var userAgent = navigator.userAgent || navigator.vendor || win.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !win.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  ///////////////////////////////
  // ***** User handling ***** //
  ///////////////////////////////

  public async getUser(): Promise<any> {
    return { customer: this.customer, userDetails: this.userDetails, userLoggedIn: this.userLoggedIn };
  }

  public async setUser(user: string, details: any = null, customer: CustomerInfo = null) {
    if (user) {
      this.settingsService.customerUserId = user;

      this.userId = user;
      this.userLoggedIn = this.userId !== null;

      if (!details) this.userDetails = await this.Api.get<any>("Settings/getUserDetails"); 
      if (!customer) this.customer = await this.Api.get<CustomerInfo>("Rental/FindCustomerByUser");
    } else {
      if (this.userLoggedIn) {
        this.settingsService.customerUserId = "";

        this.userLoggedIn = false;
        this.userDetails = null;
        this.customer = null;
        this.userId = null;
      }
    }

    this.pack();
  }

  ////////////////////////////////////////////
  // ***** Handle anticipated country ***** //
  ////////////////////////////////////////////

  public anticipatedCountryChanged: EventEmitter<Country> = new EventEmitter<Country>();

  //Get called upon when the coordinates updates in App-integration service.
  public async registerLastKnownPosition(lastknownlocation: Coordinates) {
    //If the country is not set, or the fallback have been used, we are updating the country.
    if (!this.anticipatedCountry && lastknownlocation) {
      //Return the 3 closest locations, regardless of the distance.
      const closestLocations = await this.Api.get<LocationInfo[]>("/Location/FindClosestLocations", { latitude: lastknownlocation.Latitude, longitude: lastknownlocation.Longitude, maxDistance: 0 });
      if (closestLocations && closestLocations.length > 0) {
        //Set the country of the closet location found.
        this.setAnticipatedUserCountry(closestLocations[0].Country);
      }
    }
  }

  public getAnticipatedUserCountry(): Country {
    var selectedCountry = this.anticipatedCountry;

    // Check if the provided country is valid
    if (selectedCountry && this.isValidCountry(selectedCountry)) {
      return selectedCountry as Country;
    } else {
      // Check if the language is valid and return the country based on the language
      if (this.language) {
        selectedCountry = this.getCountryByLanguage(this.language);
        if (selectedCountry) return selectedCountry as Country;
      }
    }

    // Fallback to null if no valid country or language is found
    return null;
  }

  public setAnticipatedUserCountry(value: string) {
    if (this.isValidCountry(value.toUpperCase())) {
      this.anticipatedCountry = value.toUpperCase();
      this.anticipatedCountryChanged.emit(this.anticipatedCountry as Country);
    }
  }

  private isValidCountry(value: string): boolean {
    return Object.keys(Country).includes(value);
  }

  private getCountryByLanguage(language: string): Country | null {
    switch (language.toLowerCase()) {
      case 'sv':
        return Country.SWEDEN;
      case 'da':
        return Country.DENMARK;
      case 'no':
        return Country.NORWAY;
      case 'de':
        return Country.GERMANY;
      case 'is':
        return Country.ICELAND;
      case 'pl':
        return Country.POLAND;
      default:
        return null; 
    }
  }

}

enum Country {
  SWEDEN = 'Sweden',
  DENMARK = 'Denmark',
  NORWAY = 'Norway',
  GERMANY = 'Germany',
  ICELAND = 'Iceland',
  POLAND = 'Poland'
}

