<div class="container" style="margin-top:30px;">
  <div class="row">
    <mat-form-field class="col-3 text-center">
      <input #vf0
             matInput
             type="tel"
             [(ngModel)]="verificationCode0"
             autocomplete="off"
             maxlength="1"
             class="text-center"
             (keyup)="focusNext(0, $event)" />
    </mat-form-field>
    <mat-form-field class="col-3 text-center">
      <input #vf1
             matInput
             type="tel"
             [(ngModel)]="verificationCode1"
             autocomplete="off"
             maxlength="1"
             class="text-center"
             (keyup)="focusNext(1, $event)" />
    </mat-form-field>
    <mat-form-field class="col-3 text-center">
      <input #vf2
             matInput
             type="tel"
             [(ngModel)]="verificationCode2"
             autocomplete="off"
             maxlength="1"
             class="text-center"
             (keyup)="focusNext(2, $event)" />
    </mat-form-field>
    <mat-form-field class="col-3 text-center">
      <input #vf3
             matInput
             type="tel"
             [(ngModel)]="verificationCode3"
             autocomplete="off"
             maxlength="1"
             class="text-center"
             (keyup)="focusNext(3, $event)" />
    </mat-form-field>
  </div>
</div>
