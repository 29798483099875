import { Component, ElementRef } from '@angular/core';
import { BookingModel, BookingService, LayoutService, NavigationService } from 'app-components';
import { AppIntegrationService, AppSettings, AppSettingsService } from 't4-app-integration';
import { LocationInfo, PageBase, RentalInfo, T4RentalObjectService, FindNearByOptionsResult } from 't4core';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'select-object-component',
  templateUrl: './select-object.component.html',
  styleUrls: ['./select-object.component.css']
})
export class SelectObject extends PageBase {
  public bookingState: BookingModel;
  public loading: boolean = true;

  public availableOptions: RentalInfo[] = [];

  public categorySelected: boolean = false;

  public nearByOptions: FindNearByOptionsResult;

  constructor(private navigator: NavigationService, el: ElementRef, private appService: AppIntegrationService, private BookingDataSerivce: BookingService, public layout: LayoutService, private objSvc: T4RentalObjectService, private appSettings: AppSettingsService, private router: Router) {
    super(el);
  }

  async ngOnInit() {
    this.appService.getCoordinates();
    this.bookingState = this.BookingDataSerivce.getBookingState();
    this.loading = true;
    var token = this.UI.beginLoading("Intellitrailer.Booking.BookingSummary.msgCheckingAvailability", "Checking availability", null);
    this.availableOptions = await this.getOptions(this.bookingState.booking.PickupLocation);
    if (this.availableOptions.length == 0 && this.Settings.clientSettings.ShowNearbyOptions) {
      this.nearByOptions = await this.Api.get<FindNearByOptionsResult>("RentalObject/GetNearByOptions", { locationId: this.bookingState.booking.PickupLocation.Id, categoryId: this.bookingState.objectCategory?.Id, pickupTime: this.bookingState.booking.PickupTime.toISOString(), returnTime: this.bookingState.booking.ReturnTime.toISOString() });
    };
    this.UI.loaderCompleted(token);

    if (this.bookingState.objectCategory != null) this.categorySelected = true;

    this.loading = false;

  }

  public back() {
    this.navigator.back();
  }

  public async selectOption(option: RentalInfo) {

    const varebilPrimaryObjectTypes = [657, 873, 874, 876, 933, 950, 415, 1088];

    // Special case Varebil (Bauhaus DK)
    if (varebilPrimaryObjectTypes.includes(option.PrimaryObjectTypeId)) {

      //Cannot rent the varebil before 8:00.
      if (this.bookingState.booking.PickupTime.toDate().getHours() < 8) {
        this.UI.alert("Intellitrailer.Booking.SelectObject.headVarebilLimit", "Limitation", "Intellitrailer.Booking.SelectObject.msgVarebilLimit3", "This object cant be rented before 08:00.");
        return;
      }

      // Cannot rent for more than 3h
      if (this.bookingState.booking.ReturnTime.toDate().getTime() - this.bookingState.booking.PickupTime.toDate().getTime() > 3 * 60 * 60 * 1000) {
        this.UI.alert("Intellitrailer.Booking.SelectObject.headVarebilLimit", "Limitation", "Intellitrailer.Booking.SelectObject.msgVarebilLimit", "This object can be booked for a maximum of 3 hours");
        return;
      } else
        // Cannot rent over night (min 1900)
        if (this.bookingState.booking.ReturnTime.toDate().getHours() >= 19 && this.bookingState.booking.ReturnTime.toDate().getMinutes() != 0) {
          this.UI.alert("Intellitrailer.Booking.SelectObject.headVarebilLimit", "Limitation", "Intellitrailer.Booking.SelectObject.msgVarebilLimit2", "This object must be returned before 19:00.");
          return;
        }
    }
    
   
    this.bookingState.booking.PrimaryObjectTypeId = option.PrimaryObjectTypeId;
    this.bookingState.booking.PrimaryType = option.PrimaryType;
    this.Insights.logEvent("Type selected", { RentalInfo: JSON.stringify(this.bookingState.booking) });

    this.bookingState.booking.AddedProductIds = null;
    this.bookingState.booking.AddedProducts = null;

    if (this.appSettings.contextId == "KanLeiesUnmanned_API" && varebilPrimaryObjectTypes.includes(option.PrimaryObjectTypeId)) {
      this.BookingDataSerivce.saveState(this.bookingState);
      this.navigator.executeCommand("NextInfo");
    }
    else {
      this.next();
    }
  }

  public next() {
    this.BookingDataSerivce.saveState(this.bookingState);
    this.navigator.executeCommand("Next");
  }
  public async getOptions(location: LocationInfo): Promise<RentalInfo[]> {
    this.loading = true;

    var availability: any[];

    if (this.bookingState.objectCategory?.Id == null) {
      availability = await this.objSvc.FindAvailableTypes(0, {
        Locations: [location.Id],
        From: this.bookingState.booking.PickupTime.clone(),
        To: this.bookingState.booking.ReturnTime.clone()
      });

      if (availability.length > 0)
        return availability[0].Types.map(x => {
          var bookingOption: RentalInfo = new RentalInfo();
          bookingOption.PickupLocation = location ? location : this.bookingState.booking.PickupLocation;
          bookingOption.PickupLocationId = location ? location.Id : this.bookingState.booking.PickupLocation.Id;
          bookingOption.PickupTime = this.bookingState.booking.PickupTime;
          bookingOption.ReturnTime = this.bookingState.booking.ReturnTime;
          bookingOption.PrimaryType = x;
          bookingOption.PrimaryObjectTypeId = x.Id;
          bookingOption.DiscountId = this.bookingState.booking.DiscountId;

          return bookingOption;
        });
    }
    else {
      availability = await this.objSvc.GetAvailableTypes(location.Id, this.bookingState.objectCategory.Id, this.bookingState.booking.PickupTime.clone(), this.bookingState.booking.ReturnTime.clone(), 0);
      

      if (availability.length > 0)
        return availability.map(x => {
          var bookingOption: RentalInfo = new RentalInfo();
          bookingOption.PickupLocation = location ? location : this.bookingState.booking.PickupLocation;
          bookingOption.PickupLocationId = location ? location.Id : this.bookingState.booking.PickupLocation.Id;
          bookingOption.PickupTime = this.bookingState.booking.PickupTime;
          bookingOption.ReturnTime = this.bookingState.booking.ReturnTime;
          bookingOption.PrimaryType = x;
          bookingOption.PrimaryObjectTypeId = x.Id;
          bookingOption.DiscountId = this.bookingState.booking.DiscountId;

          return bookingOption;
        });
    }
    return [];
  }

  public async selectNextSlot() {
    var x = await this.UI.confirm("Intellitrailer.Booking.Components.BookingOption.NextAvailableSlot.HeadlineInfo", "Confirm Pickup Time ", "Intellitrailer.Booking.Components.BookingOption.NextAvailableSlot.Message", "Do you want to change the pickup time?");
    if (!x) return false;
    const diffTime = this.bookingState.booking.ReturnTime.diff(this.bookingState.booking.PickupTime, 'hours')
    const pickupTime = this.nearByOptions.AvailableNextSlot;
    const returnTime = moment(pickupTime).add(diffTime, 'hours');
    this.bookingState.booking.PickupTime = pickupTime;
    this.bookingState.booking.ReturnTime = returnTime;
    var nextAvailableOption = await this.getOptions(this.bookingState.booking.PickupLocation);
    this.selectOption(nextAvailableOption[0]);
  }

  public async changeLocation(location) {
    var x = await this.UI.confirm("Intellitrailer.Booking.Components.BookingOption.NearbyLocationChange.Headline", "Confirm Location", "Intellitrailer.Booking.Components.BookingOption.NearbyLocationChange.Message", "Do you want to change the pickup location?");
    if (!x) return false;
    this.bookingState.booking.PickupLocation = location;
    this.navigator.reload();
  }

  public changeModel(type) {
    this.bookingState.booking.PrimaryObjectTypeId = type.Id;
    this.bookingState.booking.PrimaryType = type;
    this.selectOption(this.bookingState.booking);
  }

  public showMoreSlots() {
    // Redirect back to available slots
    this.router.navigate(['/Booking/DateAndTime', { redirectFromNearByoptions: true }]);

  }
  //public async getNearbyOptions(): Promise<OptionsAndLocation[]> {
  //  // PLEASE NOTE, method not in use at this time, left uncommented to the day its use returns
  //  var nearbyLocations = await this.Api.get<LocationInfo[]>("Location/FindLocations", { latitude: this.bookingState.booking.PickupLocation.Latitude, longitude: this.bookingState.booking.PickupLocation.Longitude, maxDistance: 60 });
  //  nearbyLocations.splice(nearbyLocations.findIndex(x => x.Id == this.bookingState.booking.PickupLocation.Id), 1);
  //  nearbyLocations.splice(5);

  //  var options: OptionsAndLocation[] = [];
  //  for (let location of nearbyLocations) {
  //    var availableOptionsAtLocation = await this.getOptions(location);
  //    var totalOptions = 0
  //    for (let opt of availableOptionsAtLocation) {
  //      totalOptions = totalOptions + opt.Options.length;
  //    }
  //    if (availableOptionsAtLocation.some(x => x.Options.length > 0)) {
  //      var optionsWithLocation: OptionsAndLocation = {
  //        location: location,
  //        bookingOptions: availableOptionsAtLocation,
  //        totalOptions: totalOptions
  //      };
  //      options.push(optionsWithLocation);
  //    }
  //  }
  //  this.nearbyOptionsExist = options.some(x => (x.totalOptions > 0));
  //  return options;
  //}

  //public async otherTimeSlots(): Promise<OptionsInTimeSlot[]> {
  //  // PLEASE NOTE, method not in use at this time, left uncommented to the day its use returns
  //  var optionsInTimeSlots: OptionsInTimeSlot[] = [];
  //  for (let cat of this.bookingState.objectCategories) {
  //    var slots = await this.Api.get<any[]>("RentalObject/GetAdjacentTimeSlots", { locationId: this.bookingState.booking.PickupLocation.Id, categoryId: cat.Id, pickupTime: !this.bookingState.booking.PickupTime && this.bookingState.atLocation ? moment().toISOString() : this.bookingState.booking.PickupTime.toISOString(), returnTime: this.bookingState.booking.ReturnTime.toISOString() });
  //    for (let slot of slots) {
  //      var filteredOptions = await this.filterAndConvertToBookings(slot.Types, null, slot.From, slot.To);
  //      if (filteredOptions.length > 0) {
  //        var TimeSlotOptions: OptionsInTimeSlot = {
  //          //Offset to get local time
  //          From: slot.From.clone(),//.add(-slot.From.utcOffset(), 'minutes'),
  //          To: slot.To.clone(),//.add(-slot.To.utcOffset(), 'minutes'),
  //          bookingOptions: filteredOptions
  //        };
  //        for (var i = 0; i < filteredOptions.length; i++) {
  //          for (let options of this.bookingOptions) {
  //            for (let option of options.Options) {
  //              if (filteredOptions[i].PrimaryType.Id == option.PrimaryType.Id) slot.Types.splice(i, 1);
  //            }
  //          }
  //        }
  //        if (!optionsInTimeSlots.find(x => x.From <= TimeSlotOptions.From && x.To >= TimeSlotOptions.To) && slot.Types.length > 0) {
  //          optionsInTimeSlots.push(TimeSlotOptions);
  //        }
  //      }
  //    }
  //  }
  //  return optionsInTimeSlots;
  //}
}
