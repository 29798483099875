import { Component, ElementRef, OnInit } from '@angular/core';
import { BookingService, LayoutService, NavigationService } from 'app-components';
import * as moment from 'moment';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { PageBase, RentalInfo, RentalStatus } from 't4core';

@Component({
  selector: 'app-confirmed-booking',
  templateUrl: './confirmed-booking.component.html'
})
export class ConfirmedBookingComponent extends PageBase implements OnInit {
  public quarterFromNow: moment.Moment = moment().add(30, 'minutes');
  public rentalStatus = RentalStatus;
  public booking: RentalInfo = new RentalInfo();

  constructor(private navigator: NavigationService, el: ElementRef, private appService: AppIntegrationService, public rentalService: BookingService, public layoutService: LayoutService, public appSettings: AppSettingsService) {
    super(el);
  }

  async ngOnInit(): Promise<void> {
    var booking = this.rentalService.getBookingState().booking;
    if (booking) {
      this.booking = booking;

      // If start within 15 min redirect to booking (Only in app mode)
      if (booking.PickupTime < this.quarterFromNow && this.appSettings.appType == 'app')
        this.startRental();
    }

    //If we are a web user and reach this step, we clear the user data.
    if (this.appSettings.appType == "web") {
      this.appSettings.setUser(null);
    }
  }

  public next() {
    if (this.appSettings.appType == "web" && this.rentalService.inStore) {
      /*this.navigator.removeIdleListeners();*/
    }

    this.navigator.executeCommand("MyPage");
  }

  public startRental() {
      this.navigator.executeCommand("Booking", { "rentalId": this.booking.Id });
  }
}
