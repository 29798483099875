import { AfterViewChecked, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { BookingModel, BookingService, LayoutService, NavigationResolver, NavigationService } from 'app-components';
import * as moment from 'moment';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { CustomerInfo, ObjectTypeInfo, PageBase, PriceModel } from 't4core';
import { AddonPopupComponent } from '../../dialogs/addon-popup/addon-popup.component';

@Component({
  selector: 'app-unconfirmed-booking',
  templateUrl: './unconfirmed-booking.component.html'
})
export class UnconfirmedBookingComponent extends PageBase implements OnInit, AfterViewChecked {
  public bookingState: BookingModel;
  public user: string;
  public requireVerification: boolean = false;
  public useSavedCard: boolean = true;
  public terms: boolean = false;
  public quarterFromNow: moment.Moment = moment().add(15, 'minutes');
  public hasBookingId: boolean = false;
  public currentPrice: PriceModel = new PriceModel();
  public addableProducts: ObjectTypeInfo[];
  public addonsFromPopup: ObjectTypeInfo[];
  public specialConditionApproved: boolean = false;

  constructor(private navigator: NavigationService, el: ElementRef, private appService: AppIntegrationService, private BookingDataService: BookingService, public resolver: NavigationResolver, public layout: LayoutService, public appSettings: AppSettingsService) {
    super(el);

    this.bookingState = BookingDataService.getBookingState();
    this.user = this.appSettings.userId;
  }

  async ngOnInit(): Promise<void> {
    this.hasBookingId = this.bookingState.booking && this.bookingState.booking.Id ? true : false;

    if (this.user) {

      //User logged in, check if number is verified
      this.requireVerification = await this.Api.get<boolean>("/Settings/needUserVerification");

      //Check if user has saved customer
      this.bookingState.booking.Customer = await this.Api.get<CustomerInfo>("/Rental/FindCustomerByUser");
    }

    if (!this.bookingState.booking.PrimaryObjectTypeId) {
      this.Insights.logEvent("Type is nil: ", { RentalInfo: JSON.stringify(this.bookingState.booking) });
      this.navigator.executeCommand('Back');
    }

    //If there is no Special condion on the rentalType, when we set it to true.
    if (!this.bookingState.booking.PrimaryType.SpecialCondition) {
      this.specialConditionApproved = true;
    }

  }
  async ngAfterViewChecked() {
    this.terms = this.isElementVisible('continue-to-agree');
  }

  public back() {
    this.navigator.back();
  }

  public async prepareForNext() {
    var confirm = true; // await this.showAddonPopup(); - Only show one dialog currently (kept for future change of mind)
    if (confirm) this.goNext();
  }

  public async goNext() {
    this.next();
  }

  public updateSpecialCondition(event) {
    this.specialConditionApproved = event;
  }

  public toggleSavedCard(event) {
    this.useSavedCard = event;
  }

  public toggleTerms(event) {
    this.terms = event;
  }

  public bookingUpdatedFromSummary(event) {
    this.bookingState.booking = event;
  }

  public updatePrice(event) {
    this.currentPrice = event;
  }

  @HostListener('scroll', ['$event'])
  onScroll(_: any) {
    this.terms = this.isElementVisible('continue-to-agree');
  }

  public updateAddons(event) {
    this.addableProducts = event;
  }
  private next() {
    this.BookingDataService.saveState(this.bookingState);
    this.navigator.executeCommand("Next");
  }

  private async showAddonPopup(): Promise<boolean> {
    // Loop through all products and ask if not added
    for (let product of this.addableProducts) {
      if (product.AskIfNotSelected && !product.IsSelected) {

        const dialogRef = this.Dialog.open(AddonPopupComponent, {
          width: '350px',
          position: { top: '50px' },
          data: { product: product }
        });

        var result = await dialogRef.afterClosed().toPromise();

        // Re-add if user selects that
        if (result === true) {
          this.addAddon(product);
        }
      }
    }

    return true;
  }

  private addAddon(product: ObjectTypeInfo) {
    if (this.bookingState.booking.AddedProducts.indexOf(product.Id + ";") < 0) {
      this.bookingState.booking.AddedProducts += product.Id + ";";
      this.bookingState.booking.AddedProductIds.push(product.Id);
      product.IsSelected = true;
    }

    for (var i of this.addableProducts) {
      if (i.Id == product.Id || !i.IsSelected) continue;

      if (i.CheckCondition && eval(i.CheckCondition) !== true) {
        this.removeAddon(i);
      }
    }
  }

  private removeAddon(product: ObjectTypeInfo) {
    product.IsSelected = false;

    this.bookingState.booking.AddedProducts = this.bookingState.booking.AddedProducts.replace(product.Id + ";", "");
    if (this.bookingState.booking.AddedProductIds.indexOf(product.Id) >= 0) this.bookingState.booking.AddedProductIds.splice(this.bookingState.booking.AddedProductIds.indexOf(product.Id), 1);
  }
  private isElementVisible(elementId: string): boolean {
    let rect = document.getElementById(elementId)?.getBoundingClientRect();
    if (!rect) {
      return false;
    }
    return rect.top >= 0 && rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth);
  }
}
