<app-view-title Title="{{ 'Intellitrailer.Booking.SelectObject.TitleType' | translate : 'Choose trailer type' }}"></app-view-title>
<div class="view-content no-padding">
  <div class="trailer-icon-big d-block d-md-none" *ngIf="!categorySelected"></div>
  <div class="trailer-icon-big d-block d-md-none" [style.background-image]="'url(' + bookingState.objectCategory.ImageUri + ')'" *ngIf="categorySelected && bookingState.objectCategory.ImageUri"></div>
  <div class="trailer-measurement-info d-block d-md-none">X: {{ 'Intellitrailer.Booking.SelectObject.TotalWeight' | translate : 'Total weight' }}, Y: {{ 'Intellitrailer.Booking.SelectObject.LoadWeight' | translate : 'Load weight' }}</div>
  <div class="trailer-measurement-info d-block d-md-none" *ngIf="categorySelected">L: {{ 'Intellitrailer.Booking.SelectObject.Length' | translate : 'Length' }}, W: {{ 'Intellitrailer.Booking.SelectObject.Width' | translate : 'Width' }}, H: {{ 'Intellitrailer.Booking.SelectObject.Height' | translate : 'Height' }}</div>

  <div class="object-wrapper">
    <div class="select-object-info-heading">
      <div class="select-object-info-location">
        {{ bookingState.booking.PickupLocation.Name }}
      </div>
      <div class="select-object-info-text">
        <p *ngIf="availableOptions.length > 0" class="text-center">
          {{ 'Intellitrailer.Booking.SelectObject.SelectObject' | translate : 'Select trailer' }}
        </p>
        <p *ngIf="availableOptions.length === 0 && !this.Settings.clientSettings.ShowNearbyOptions" style="height: 70vh" class="text-center">
          {{ 'Intellitrailer.Booking.SelectObject.NoAvailableObject' | translate : 'No available trailers' }}
        </p>
        <div *ngIf="availableOptions.length === 0 && this.Settings.clientSettings.ShowNearbyOptions" style="height: 70vh" class="text-center">
          <p>{{ 'Intellitrailer.Booking.SelectObject.AvailableOptions' | translate : 'Available Options' }}</p>
          <div *ngIf="nearByOptions" class="nearby-options-container">
            <div *ngIf="nearByOptions.AvailableNextSlot" style=" padding-top: 10px;">
              <p style="text-align: center; "></p>
              <div class="row d-flex justify-content-center p-3">
                <p class="available-time-info">
                  {{ 'Intellitrailer.Booking.SelectObject.NextAvailableTimeInfo' | translate : 'We found no trailer matching your request.The first available time is at ' }}
                  <b>{{ nearByOptions.AvailableNextSlot | date : 'yyyy-MM-dd HH:mm' }}</b>
                </p>
                <br />
                <button class="btn-select-slot" type="button" mat-raised-button (click)="selectNextSlot()">
                  <strong>
                    {{ 'Intellitrailer.Booking.Components.BookingOption.OkChoose' | translate : 'Ok, choose '}}{{ nearByOptions.AvailableNextSlot | date : 'HH:mm' }}
                  </strong>
                </button>
                <a (click)="showMoreSlots()" class="link">{{ 'Intellitrailer.Booking.Components.BookingOption.ShowAllAvailableTimes' | translate : 'Show all available times' }}</a>
              </div>
            </div>
            <div *ngIf="nearByOptions.NearByLocations.length > 0 || nearByOptions.OtherCategoryObjectTypes.length > 0" class="title-with-divider">
              <div class="title">{{ 'Intellitrailer.Booking.Components.BookingOption.AvailableAlternatives' | translate : 'Available Alternatives' }}</div>
            </div>
            <mat-tab-group *ngIf="nearByOptions">
              <mat-tab *ngIf="nearByOptions.OtherCategoryObjectTypes.length > 0" label="{{ 'Intellitrailer.Booking.Components.BookingOption.Availablle Models' | translate : 'Available Models ' }}">
                <div *ngIf="nearByOptions.OtherCategoryObjectTypes.length > 0">
                  <div *ngFor="let type of nearByOptions.OtherCategoryObjectTypes">
                    <div class="minimal-item-container d-blok d-md-none" *ngIf="type">
                      <div class="container">
                        <div class="row" (click)="changeModel(type)">
                          <div class="col-3">
                            <div class="item-image" style="background-image: url({{ type.PictureUri }});"></div>
                          </div>
                          <div class="col-9">
                            <div class="row">
                              <div class="col-9">{{ type.Alias }}</div>
                              <div class="col-12 font-weight-normal">L: {{ type.Length }}cm, W: {{ type.Width }}cm, H: {{ type.Height }}cm<br /> X: {{ type.TotalWeight }}kg, Y: {{ type.MaxLoad }}kg</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="nearByOptions.NearByLocations.length > 0" label="{{ 'Intellitrailer.Booking.Components.BookingOption.ShowNearbyLocations' | translate : 'Show Nearby Locations' }}">
                <div *ngIf="nearByOptions.NearByLocations">
                  <div *ngFor="let loc of nearByOptions.NearByLocations" class="row d-flex justify-content-between p-2 border-bottom" (click)="changeLocation(loc)">
                    <div class="col-8 d-flex justify-content-start">
                      <span style="font-weight: normal; text-align: start; padding: 5px;">{{loc.Name}}</span>
                    </div>
                    <div class="col-4">
                      <span style="font-weight: normal; padding: 5px;">{{loc.Distance | number : '0.1-1'}}km</span>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="availableOptions.length > 0" class="select-object-divider d-block d-md-none"></div>
    <div style="display: flex; flex-wrap: wrap;" class="justify-content-center">
      <app-booking-option *ngFor="let option of availableOptions"
                          class="col-md-4 type-group"
                          [booking]="option"
                          (click)="selectOption(option)">
      </app-booking-option>
    </div>
  </div>
  <!--<div class="view-actions white-fade-out-bg" *ngIf="(bookingState && bookingState.booking && bookingState.booking.Id && bookingState.booking.PrimaryObjectTypeId)">
    <button *ngIf="!loading" type="button" mat-raised-button (click)="next()" [disabled]="!bookingState.booking.PrimaryType">{{ 'Intellitrailer.Navigation.Next' | translate : 'Next' }}</button>
  </div>-->
</div>

